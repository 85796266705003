<template>
  <div class="mb-6 mt-6">

    <ed-table
      strModel="System/Alteracao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :disabledFilters="false"
      @search="getEmail"
      @filter="getEmail"
      @register="onRegister"
      disabledRegisterRows
      disabledSearchRows
      disabledEditRows
      disabledDeleteRows
    >
      <!-- <template slot="table-filter">
        <ed-input-auto-complete
          v-if="!formData.intModel"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.strNomeDatabase"
          label="Cliente"
          name="strNomeDatabase"
          item-text="strNome"
          item-value="strNomeDatabase"
          route="System/Cliente"
          :filters="{}"
          clearable
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data da Ocorrência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />


        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayStatus"
          label="Status"
          name="arrayStatus"
          :items="bind.arrayStatus"
          clearable
          multiple
        />
      </template> -->
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputTipo,
  EdInputSelect,
  EdInputDate,
  EdInputText,
  EdInputEditor,
  EdForm,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    headers: {
      type: [Array],
      required: false,
      default: function () {
        return [];
      },
    },
  },
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputTipo,
    EdInputSelect,
    EdInputDate,
    EdInputText,
    EdInputEditor,
    EdForm,
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {},
      formFiltros: {
        intModel: this.formData.intModel,
        strModel: this.formData.strModel,
        strIdentificador: this.formData.strIdentificador,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
      formDataLog: null,
      arrayHeaders: [],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {
    intModel() {
      this.initialize();
    },
    strModel() {
      this.initialize();
    },
    headers() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.headers.forEach((element) => {
        this.arrayHeaders.push(element);
      });

      this.arrayHeaders.push({
        text: "Data Atualização",
        sortable: true,
        value: "strDataAtualizacao",
      });
      this.getEmail();
    },

    onRegister() {
      this.bind.boolExibirCadastro = true;
    },

    visualizarLog(data) {
      this.bind.boolExibirLog = true;
      this.formDataLog = Object.assign(data, {});
    },

    getEmail() {
      if (!this.formData.intModel || !this.formData.strModel) return;

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Alteracao", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {

              let item = {
                intId: objModel._id,
                strDataAtualizacao: this.$utilidade.toDate(
                  objModel.strDataAtualizacao
                ),
                buttons: [],
                _item: objModel,
              };


              console.log('objModel',objModel)
              this.arrayHeaders.forEach((header) => {
                if(objModel.strDadosAlterados && ['strDataAtualizacao','usuario'].indexOf(header.value) == -1){
                  item[header.value] = objModel.strDadosAlterados[header.value]

                  if(header.value.indexOf('strData') != -1 && item[header.value]){
                    item[header.value] = this.$utilidade.toDate(item[header.value])
                  }

                  if(header.value.indexOf('float') != -1){
                    item[header.value] = this.$utilidade.floatToMoney(item[header.value])
                  }
                }
              });

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
