var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ed-modal',{attrs:{"width":"70%","scrollable":false},model:{value:(_vm.formFiltros.boolExibirModalEdit),callback:function ($$v) {_vm.$set(_vm.formFiltros, "boolExibirModalEdit", $$v)},expression:"formFiltros.boolExibirModalEdit"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Inserir Novo Serviço :: "+_vm._s(_vm.formData.destino ? _vm.formData.destino.strNome : "")+" ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-tabs',{attrs:{"centered":"","icons-and-text":""},model:{value:(_vm.strTab),callback:function ($$v) {_vm.strTab=$$v},expression:"strTab"}},[_c('v-tab',{attrs:{"href":"#tab-servico-geral"}},[_vm._v(" Serviço Faturado "),_c('i',{class:_vm.$utilidade.getIcone('servico') + ' fa-2x'})]),_c('v-tab',{attrs:{"href":"#tab-servico-avulso"}},[_vm._v(" Serviço Avulso "),_c('i',{class:_vm.$utilidade.getIcone('servico') + ' fa-2x'})])],1),(_vm.strTab == 'tab-servico-geral')?_c('ed-simple-table',{attrs:{"config":{ filterName: 'Buscar' },"filter":_vm.formFiltros,"boolFiltroAberto":"","disabledRegisterRows":"","disabledSearchRows":""},on:{"filter":_vm.buscarServicoFaturado}},[_c('template',{slot:"table-filter"},[_c('ed-input-date',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",attrs:{"label":"Data da Ocorrência","name":"arrayData","multiple":"","clearable":""},model:{value:(_vm.formFiltros.arrayDataCadastro),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayDataCadastro", $$v)},expression:"formFiltros.arrayDataCadastro"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",attrs:{"label":"Unidade de Trabalho","name":"intCadastroGeralUnidadeId","item-text":"strNome","route":"Cadastro/CadastroGeralUnidade","filters":{
              intCadastroGeralEmpresaId: this.formData.intDestinoId,
            },"clearable":""},model:{value:(_vm.formFiltros.intCadastroGeralUnidadeId),callback:function ($$v) {_vm.$set(_vm.formFiltros, "intCadastroGeralUnidadeId", $$v)},expression:"formFiltros.intCadastroGeralUnidadeId"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",attrs:{"label":"Setor","name":"intCadastroGeralSetorId","item-text":"strNome","route":"Cadastro/CadastroGeralSetor","filters":{
              intCadastroGeralEmpresaId: this.formData.intDestinoId,
            },"clearable":""},model:{value:(_vm.formFiltros.intCadastroGeralSetorId),callback:function ($$v) {_vm.$set(_vm.formFiltros, "intCadastroGeralSetorId", $$v)},expression:"formFiltros.intCadastroGeralSetorId"}})],1),_c('template',{slot:"table-filter-button"}),_c('thead',[_c('tr',[_c('th',{attrs:{"width":"50"}},[_c('ed-input-checkbox',{attrs:{"disabled":_vm.arrayServico.length ? false : true},on:{"input":_vm.selecionarTodosServicos},model:{value:(_vm.formFiltros.boolSelecionarTudo),callback:function ($$v) {_vm.$set(_vm.formFiltros, "boolSelecionarTudo", $$v)},expression:"formFiltros.boolSelecionarTudo"}})],1),_c('th',{attrs:{"width":"50"}},[_vm._v("#")]),_c('th',[_vm._v("Serviço")]),_c('th',[_vm._v("Unidade")]),_c('th',[_vm._v("Setor")]),_c('th',{attrs:{"width":"180"}},[_vm._v("Data")]),_c('th',{attrs:{"width":"180"}},[_vm._v("Valor")])])]),_c('tbody',[[_vm._l((_vm.arrayServico),function(servico,index){return _c('tr',{key:index},[_c('td',[_c('ed-input-checkbox',{on:{"input":_vm.calcularServicoSelecionado},model:{value:(servico.boolSelecionado),callback:function ($$v) {_vm.$set(servico, "boolSelecionado", $$v)},expression:"servico.boolSelecionado"}})],1),_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(servico.strParametroServicoNome)+" "),(servico.intConsultaId)?_c('span',{staticClass:"ed-table-info ed-full-width",attrs:{"title":"Consulta"}},[_c('a',{attrs:{"target":"_blank","href":_vm.$root.$session.urlSistema +
                      '/consulta/editar/' +
                      servico.intConsultaId}},[_c('i',{class:_vm.$utilidade.getIcone('consulta')}),_vm._v(" CONSULTA "+_vm._s(servico.intConsultaId)+" ")])]):_vm._e(),(servico.exame)?_c('span',{staticClass:"ed-table-info ed-full-width",attrs:{"title":"Exame"}},[_c('a',{attrs:{"target":"_blank","href":_vm.$root.$session.urlSistema +
                      '/cadastro/exame/editar/' +
                      servico.exame.intId}},[_c('i',{class:_vm.$utilidade.getIcone('exame')}),_vm._v(" "+_vm._s(servico.exame.strNome)+" ")])]):_vm._e(),(servico.funcionario)?_c('span',{attrs:{"title":"Funcionário"}},[_c('a',{attrs:{"target":"_blank","href":_vm.$root.$session.urlSistema +
                      '/cadastro/funcionario/editar/' +
                      servico.funcionario.intId}},[_c('i',{class:_vm.$utilidade.getIcone('funcionario')}),_vm._v(" "+_vm._s(servico.funcionario.strNome)+" ")])]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(servico.strCadastroGeralUnidadeNome)+" ")]),_c('td',[_vm._v(" "+_vm._s(servico.strCadastroGeralSetorNome)+" ")]),_c('td',[_vm._v(_vm._s(_vm.$utilidade.toDate(servico.strDataServico, true)))]),_c('td',[_vm._v(_vm._s(_vm.$utilidade.floatToMoney(servico.floatValor)))])])}),(!_vm.arrayServico.length)?_c('tr',[_c('td',{attrs:{"colspan":"7","align":"center"}},[_vm._v(" Nenhum serviço encontrado para o período selecionado ")])]):_vm._e(),(_vm.arrayServico.length)?_c('tr',[_c('td'),_c('td'),_c('th'),_c('td'),_c('td'),_c('th',[_vm._v("TOTAL SELECIONADO")]),_c('th',[_vm._v(" R$ "+_vm._s(_vm.$utilidade.floatToMoney(_vm.formFiltros.floatTotal))+" ("+_vm._s(_vm.formFiltros.intTotalServico)+") ")])]):_vm._e()]],2)],2):_vm._e(),(_vm.strTab == 'tab-servico-avulso')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-left"},[_c('ed-button',{staticClass:"mt-4 mb-2",attrs:{"label":"Adicionar nova linha de Serviço","iconLeft":_vm.$utilidade.getIcone('cadastrar'),"size":"100%","color":"primary"},on:{"click":_vm.inserirServicoAvulso}})],1)]),_vm._l((_vm.arrayServico),function(servico,index){return [_c('div',{key:index,staticClass:"row"},[_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-0 pt-0",attrs:{"label":"Serviço","name":"intParametroServicoId","items":_vm.bind.arrayServico,"rules":"required"},on:{"selected":function (evento) {
                  servico.servico = evento ? evento : null;
                }},model:{value:(servico.intParametroServicoId),callback:function ($$v) {_vm.$set(servico, "intParametroServicoId", $$v)},expression:"servico.intParametroServicoId"}},[_c('template',{slot:"append"},[_c('ed-button',{staticClass:"mt-0 pa-0",attrs:{"iconLeft":_vm.$utilidade.getIcone('cadastrar'),"onlyIcon":"","color":"success","small":"","text":""},on:{"click":function($event){return _vm.exibirCadastroServico()}}})],1)],2),_c('ed-input-money',{staticClass:"col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-0 pt-0",attrs:{"label":"Valor","name":"floatValor","rules":"required"},on:{"input":_vm.calcularServicoSelecionado},model:{value:(servico.floatValor),callback:function ($$v) {_vm.$set(servico, "floatValor", $$v)},expression:"servico.floatValor"}}),_c('ed-button',{staticClass:"col-2 mt-7",attrs:{"iconLeft":_vm.$utilidade.getIcone('deletar'),"disabled":index == 0,"icon":"","onlyIcon":"","color":"error"},on:{"click":function($event){return _vm.removerServicoAvulso(index)}}})],1)]})]:_vm._e(),(_vm.arrayServico.length && _vm.strTab == 'tab-servico-geral')?_c('div',{staticClass:"d-flex justify-content-center"},[_c('ed-alert',{staticClass:"mt-3 mb-3 col-4",attrs:{"type":"warning"}},[_vm._v("Para continuar selecione pelo menos 1 serviço")])],1):_vm._e()],2),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('ed-button',{staticClass:"mt-4 mb-2",attrs:{"label":'Inserir Serviços Selecionados (R$ ' +
          _vm.$utilidade.floatToMoney(_vm.formFiltros.floatTotal) +
          ')',"iconLeft":_vm.$utilidade.getIcone('salvar'),"disabled":_vm.formFiltros.intTotalServico == 0,"size":"100%","color":"primary"},on:{"click":_vm.inserirServico}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }