<template>
  <div>
    <!--CADASTRAR CATEGORIA-->
    <ed-modal v-model="boolExibirCadastroCategoria" width="90%">
      <div slot="title">Inserir Nova Categoria</div>

      <div class="" slot="content" v-if="boolExibirCadastroCategoria">
        <parametro
          v-if="intTipoMovimentoId == 1444"
          strChave="financeiroCategoriaDespesa"
          :key="'tab-categoria-receita-' + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroCategoria = false;
            }
          "
          disableIcon
          disableColor
          disableCodigo
        />

        <parametro
          v-if="intTipoMovimentoId == 1443"
          strChave="financeiroCategoriaReceita"
          :key="'tab-categoria-receita-' + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroCategoria = false;
            }
          "
          disableIcon
          disableColor
          disableCodigo
        />
      </div>
    </ed-modal>

    <!--CADASTRAR SERVICO-->
    <ed-modal v-model="boolExibirCadastroServico" width="40%">
      <div slot="title">Inserir Novo Serviço</div>

      <div class="" slot="content" v-if="boolExibirCadastroServico">
        <parametro
          strChave="financeiroServico"
          :key="'financeiroServico-' + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroServico = false;
            }
          "
          disableIcon
          disableColor
          disableCodigo
        />
      </div>
    </ed-modal>

    <!--CADASTRAR FORMA DE PAGAMENTO-->
    <ed-modal v-model="boolExibirCadastroFormaPagamento" width="90%">
      <div slot="title">Inserir Nova Categoria</div>

      <div class="" slot="content" v-if="boolExibirCadastroFormaPagamento">
        <parametro
          strChave="financeiroFormaPagamento"
          :key="'financeiroFormaPagamento-' + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroFormaPagamento = false;
            }
          "
          disableIcon
          disableColor
          disableCodigo
        />
      </div>
    </ed-modal>

    <!--CADASTRAR CLIENTE FORNECEDOR-->
    <ed-modal v-model="boolExibirCadastroCliente" width="90%">
      <div slot="title">Inserir Novo Cliente</div>

      <div class="" slot="content" v-if="boolExibirCadastroCliente">
        <cliente
          v-if="formData.strDestinoType == 'App\\Models\\Tenant\\CadastroGeral'"
          :intCadastroGeralId="0"
          :intTipoCadastroGeralId="0"
          :key="formData.strDestinoType + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroCliente = false;
              formData.intDestinoId = $event.intId;
            }
          "
        />

        <profissional
          v-if="formData.strDestinoType != 'App\\Models\\Tenant\\CadastroGeral'"
          :intProfissionalId="0"
          :boolCadastroRapido="true"
          :key="formData.strDestinoType + $root.$session.versao"
          @salvar="
            ($event) => {
              initialize();
              boolExibirCadastroCliente = false;
              formData.intDestinoId = $event.intId;
            }
          "
        />
      </div>
    </ed-modal>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Informações do lançamento</span>

        <div class="row">
          <ed-input-select
            v-if="$root.$session.filiais.length > 1"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formData.intFilialId"
            label="Filial"
            name="filiais"
            rules="required"
            :items="$root.$session.filiais"
            :disabled="formData.intId ? true : false"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formData.strDestinoType"
            label="Filtrar por"
            name="strDestinoType"
            rules="required"
            :items="bind.arrayTipoDestino"
            :disabled="formData.intId ? true : false"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0"
            v-model="formData.intDestinoId"
            label="Cliente/Fornecedor"
            name="intDestinoId"
            :route="
              formData.strDestinoType == 'App\\Models\\Tenant\\CadastroGeral'
                ? 'Cadastro/CadastroGeral'
                : 'Cadastro/Profissional'
            "
            :filters="{
              filiais: [formData.intFilialId],
              strBusca: formData.intDestinoId,
            }"
            :itemList="formData.destino ? [formData.destino] : []"
            :initializeItems="true"
            :disabled="
              !bind.boolLancamentoBloqueado &&
              (formData.intFilialId || formData.intId)
                ? false
                : true
            "
            @selected="setCadastroGeralEmpresa"
            rules="required"
          >
            <template slot="append">
              <ed-button
                @click="boolExibirCadastroCliente = !boolExibirCadastroCliente"
                :iconLeft="$utilidade.getIcone('cadastrar')"
                class="mt-0 pa-0"
                onlyIcon
                color="success"
                small
                text
              />
            </template>
          </ed-input-auto-complete>

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.intParametroCategoriaId"
            :disabled="
              !bind.boolLancamentoBloqueado &&
              (formData.intDestinoId || formData.intId)
                ? false
                : true
            "
            label="Categoria"
            name="intParametroCategoriaId"
            :items="bind.arrayCategoria"
            rules="required"
          >
            <template slot="append">
              <ed-button
                @click="
                  boolExibirCadastroCategoria = !boolExibirCadastroCategoria
                "
                :iconLeft="$utilidade.getIcone('cadastrar')"
                class="mt-0 pa-0"
                onlyIcon
                color="success"
                small
                text
              />
            </template>
          </ed-input-select>

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.intParametroClassificacaoId"
            :disabled="
              !bind.boolLancamentoBloqueado &&
              (formData.intDestinoId || formData.intId)
                ? false
                : true
            "
            label="Classificação"
            name="intParametroClassificacaoId"
            :items="bind.arrayClassificacao"
          >
            <template slot="append">
              <ed-button
                @click="
                  boolExibirCadastroCategoria = !boolExibirCadastroCategoria
                "
                :iconLeft="$utilidade.getIcone('cadastrar')"
                class="mt-0 pa-0"
                onlyIcon
                color="success"
                small
                text
              />
            </template>
          </ed-input-select>

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.strDescricao"
            :disabled="formData.intDestinoId ? false : true"
            name="strDescricao"
            label="Descrição"
            rules="required"
            @input="formatarParcelamento"
          />

        </div>

        <span class="form-title">Serviços</span>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0">
            <ed-servico :formData="formData" />
          </div>
        </div>

        <template v-if="formData.servicos.length">
          <span class="form-title">Condição de pagamento</span>

          <ed-movimento :formData="formData" :key="$root.$session.versao" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdModal,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputTipo,
  EdInputDate,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputMoney,
  EdButton,
} from "@/components/common/form";

import EdServico from "./servico.vue";
import EdMovimento from "./movimento.vue";
import Parametro from "@/components/common/parametro/editar";
import Cliente from "@/components/cadastro/geral/editar";
import Profissional from "@/components/cadastro/profissional/editar";

export default {
  name: "pageCadastroFuncao",
  inject: [
    "bind",
    "intTipoMovimentoId",
    "getTotalMovimento",
    "getPercentualDesconto",
    "getPercentualAcrescimo",
    "initialize",
    "isMovimentoFinalizado",
  ],
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdModal,
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputTipo,
    EdInputDate,
    EdInputAutoComplete,
    EdServico,
    EdMovimento,
    EdInputCheckbox,
    EdInputMoney,
    EdButton,
    Parametro,
    Cliente,
    Profissional,
  },
  provide() {
    return {
      getTotalMovimento: this.getTotalMovimento,
      getPercentualDesconto: this.getPercentualDesconto,
      getPercentualAcrescimo: this.getPercentualAcrescimo,
      intTipoMovimentoId: this.intTipoMovimentoId,
      formatarParcelamento: this.formatarParcelamento,
      replicarParcelamento: this.replicarParcelamento,
      setStatusFinanceiro: this.setStatusFinanceiro,
      isMovimentoFinalizado: this.isMovimentoFinalizado,
      calcularServico: this.calcularServico,
      exibirFormaPagamento: this.exibirFormaPagamento,
      exibirCadastroServico: this.exibirCadastroServico,
      bind: this.bind,
    };
  },
  mounted() {},
  created() {},
  data() {
    return {
      boolExibirCadastroCategoria: false,
      boolExibirCadastroCliente: false,
      boolExibirCadastroFormaPagamento: false,
      boolExibirCadastroServico: false,
    };
  },
  watch: {},
  methods: {
    setCadastroGeralEmpresa(item) {
      this.formData.empresa = item;
    },

    setStatusFinanceiro(item) {
      if (item) {
        this.formData.status = item;
      }
    },

    exibirFormaPagamento() {
      this.boolExibirCadastroFormaPagamento =
        !this.boolExibirCadastroFormaPagamento;
    },

    exibirCadastroServico() {
      this.boolExibirCadastroServico = !this.boolExibirCadastroServico;
    },

    calcularServico() {
      let floatTotal = 0;
      let intTotalServico = 0;
      this.formData.servicos.forEach((element) => {
        floatTotal += Number(this.$utilidade.moneyToFloat(element.floatValor));
        intTotalServico++;
      });

      this.formData.floatTotalMovimento = floatTotal;
      // this.formData.movimentos[0].floatValor = this.getTotalMovimento();
      // this.formData.movimentos[0].strDescricao = this.formData.strDescricao;
      this.formatarParcelamento();
    },

    replicarParcelamento() {
      if (this.formData.movimentos.length <= 1) return;

      let objMovimentoReferencia = _.cloneDeep(this.formData.movimentos[0]);

      this.formData.movimentos.forEach((objMovimento, index) => {
        if (index > 0) {
          objMovimento.intParametroFormaPagamentoId =
            objMovimentoReferencia.intParametroFormaPagamentoId;
          (objMovimento.intContaBancariaId =
            objMovimentoReferencia.intContaBancariaId),
            (objMovimento.strDataCompentencia = this.$utilidade.addDate(
              index,
              this.$utilidade.convertDate(
                objMovimentoReferencia.strDataVencimento,
                "YYYY-MM-DD"
              ),
              "M",
              "DD/MM/YYYY"
            ));
          objMovimento.strDataVencimento = this.$utilidade.addDate(
            index,
            this.$utilidade.convertDate(
              objMovimentoReferencia.strDataVencimento,
              "YYYY-MM-DD"
            ),
            "M",
            "DD/MM/YYYY"
          );
        }
      });
    },

    formatarParcelamento() {
      if (this.formData.intId && this.bind.boolLancamentoBloqueado) return;

      let movinentosClone = [];
      let objMovimentoClone = _.cloneDeep(this.formData.movimentos[0]);
      let strDataVencimento = objMovimentoClone.strDataVencimento
        ? objMovimentoClone.strDataVencimento
        : this.$utilidade.toDate(null, true);
      let floatTotalPago = 0;
      let intTotalParcela = 0;

      strDataVencimento = this.$utilidade.convertDate(
        strDataVencimento,
        "YYYY-MM-DD"
      );

      if (objMovimentoClone.intParcelamento > 1) {
        //PEGAR AS PARCELAS PAGAS E O TOTAL DE PARCELAS PARA DIVIDIR O PACELAMENTO
        for (
          let index = 1;
          index <= objMovimentoClone.intParcelamento;
          index++
        ) {
          const objMovimentoItinerado = this.formData.movimentos[index - 1];
          let boolEfetuarRateio = true;

          if (objMovimentoItinerado && objMovimentoItinerado.status) {
            if (
              objMovimentoItinerado.status.strChave ==
              "financeiroStatusRecebido"
            ) {
              floatTotalPago += Number(
                this.$utilidade.moneyToFloat(objMovimentoItinerado.floatValor)
              );
              boolEfetuarRateio = false;
            }
          }

          if (boolEfetuarRateio) {
            intTotalParcela++;
          }
        }

        if (!intTotalParcela) {
          this.$root.$dialog.warning(
            "Nao e possivel realizar um parcelamento com todos os pagamento efetuados."
          );
          return;
        }

        let floatTotalMovimento = this.getTotalMovimento() - floatTotalPago;

        let floatValorParcela = Number(
          (floatTotalMovimento / intTotalParcela).toFixed(2)
        );
        let flaotValorTotalAplicado = 0;
        let floatDiferenca = 0;

        objMovimentoClone.strDescricao =
          (this.formData.strDescricao ? this.formData.strDescricao : "") +
          " 1/" +
          objMovimentoClone.intParcelamento;
        objMovimentoClone.floatValor = floatValorParcela;

        for (
          let index = 1;
          index <= objMovimentoClone.intParcelamento;
          index++
        ) {
          let objMovimentoItinerado = this.formData.movimentos[index - 1];

          if (
            objMovimentoItinerado &&
            objMovimentoItinerado.status &&
            objMovimentoItinerado.status.strChave == "financeiroStatusRecebido"
          ) {
            movinentosClone.push(objMovimentoItinerado);
          } else {
            let item = {
              intId: objMovimentoItinerado ? objMovimentoItinerado.intId : 0,
              intParcelamento: index,
              boolPagamentoEfetuado: 0,
              boolPagamentoCancelado: 0,
              boolExibir: 1,
              strDataCompentencia:
                index == 1
                  ? strDataVencimento
                  : this.$utilidade.addDate(
                      index - 1,
                      strDataVencimento,
                      "M",
                      "DD/MM/YYYY"
                    ),
              strDataVencimento:
                index == 1
                  ? strDataVencimento
                  : this.$utilidade.addDate(
                      index - 1,
                      strDataVencimento,
                      "M",
                      "DD/MM/YYYY"
                    ),
              strDataPagamento: null,
              strDescricao:
                (this.formData.strDescricao ? this.formData.strDescricao : "") +
                " " +
                index +
                "/" +
                objMovimentoClone.intParcelamento,
              intParametroFormaPagamentoId:
                objMovimentoClone.intParametroFormaPagamentoId,
              intParametroStatusId: null,
              floatValor: Number(floatValorParcela),
              intContaBancariaId: objMovimentoClone.intContaBancariaId,
            };

            flaotValorTotalAplicado += Number(floatValorParcela);

            movinentosClone.push(item);
          }
        }

        floatDiferenca = Number(
          (floatTotalMovimento - flaotValorTotalAplicado).toFixed(2)
        );


        if (floatDiferenca) {
          movinentosClone[movinentosClone.length - 1].floatValor +=
            this.$utilidade.moneyToFloat(movinentosClone.floatValor) +
            floatDiferenca;
        }
      } else {
        movinentosClone.push(objMovimentoClone);
        movinentosClone[0].intParcelamento = 1;
        movinentosClone[0].floatValor = this.getTotalMovimento();
      }

      if (movinentosClone.length) {
        movinentosClone[0].intParcelamento = objMovimentoClone.intParcelamento;
        this.formData.movimentos = movinentosClone;
      }
    },
  },
};
</script>
