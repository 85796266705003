<template>
  <div>
    <!--PRIMEIRA PARCELA-->
    <v-card class="mb-6">
      <v-tabs
        v-model="strTab"
        bg-color="deep-purple-accent-4"
        centered
        icons-and-text
      >
        <v-tab href="#tab-principal">
          <v-icon>mdi-phone</v-icon>
          Informações do Pagamento
          <i :class="$utilidade.getIcone('detalhes') + ' fa-2x'"></i>
        </v-tab>

        <v-tab
          href="#tab-anexo"
          v-if="formData.intId && formData.movimentos.length <= 1"
        >
          Anexos
          <i :class="$utilidade.getIcone('anexo') + ' fa-2x'"></i>
        </v-tab>
        <v-tab
          href="#tab-cobranca"
          v-if="
            formData.intId &&
            formData.movimentos.length <= 1 &&
            formData.intTipoMovimentoId == 1443
          "
        >
          Enviar Cobrança por Email
          <i :class="$utilidade.getIcone('enviar') + ' fa-2x'"></i>
        </v-tab>
        <v-tab
          href="#tab-boleto"
          v-if="
            formData.intId &&
            formData.movimentos.length <= 1 &&
            formData.intTipoMovimentoId == 1443
          "
        >
          Gerar Boleto de Cobrança
          <i :class="$utilidade.getIcone('pdf') + ' fa-2x'"></i>
        </v-tab>
        <v-tab
          href="#tab-nfse"
          v-if="
            formData.intId &&
            formData.movimentos.length <= 1 &&
            formData.intTipoMovimentoId == 1443 &&
            $root.$session.verificarPermissaoModulo(5) && 
            $root.$session.verificarPermissaoMenu('nfse.emitir')
          "
        >
          Gerar Nota Fiscal de Serviço
          <i :class="$utilidade.getIcone('xml') + ' fa-2x'"></i>
        </v-tab>
        
      </v-tabs>

      <v-card-text>
        <template v-if="strTab == 'tab-principal'">
          <div class="row">
            <template>
              <ed-input-select
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0 pr-0 pl-2"
                v-model="formData.movimentos[0].intParcelamento"
                :disabled="!formData.intDestinoId ? true : false"
                label="Parcelamento"
                name="intParcelamento"
                :items="bind.arrayParcelamento"
                rules="required"
                @input="formatarParcelamento"
              />

              <ed-input-money
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0 pr-0 pl-2"
                v-model="formData.floatTotalDesconto"
                label="Desconto"
                @input="calcularServico"
                :disabled="
                  bind.boolLancamentoBloqueado || bind.boolLancamentoPago
                    ? true
                    : false
                "
              >
                <span class="" slot="append">R$</span>
              </ed-input-money>

              <ed-input-money
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0 pr-2 pl-2"
                v-model="formData.floatTotalAcrescimento"
                label="Acréscimo"
                @input="calcularServico"
                :disabled="
                  bind.boolLancamentoBloqueado || bind.boolLancamentoPago
                    ? true
                    : false
                "
              >
                <span class="" slot="append">R$</span>
              </ed-input-money>

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0 pl-2"
                v-model="formData.movimentos[0].strDataVencimento"
                :disabled="
                  !formData.intDestinoId || bind.boolLancamentoPago
                    ? true
                    : false
                "
                :label="
                  formData.movimentos[0].intParcelamento > 0
                    ? '1 º Vencimento'
                    : 'Data de Vencimento'
                "
                name="strDataVencimento"
                rules="required"
                @input="replicarParcelamento"
              />

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0 pl-2"
                v-if="formData.movimentos.length <= 1"
                v-model="formData.movimentos[0].strDataCompentencia"
                :disabled="
                  !formData.intDestinoId || bind.boolLancamentoPago
                    ? true
                    : false
                "
                label="Data Competência"
                name="strDataCompentencia"
                rules="required"
                @input="replicarParcelamento"
              />

              <ed-input-auto-complete
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0 pl-2"
                v-model="formData.movimentos[0].intContaBancariaId"
                :disabled="
                  !formData.intDestinoId || bind.boolLancamentoPago
                    ? true
                    : false
                "
                label="Conta Bancária"
                name="intContaBancariaId"
                route="Configuracoes/ContaBancaria"
                :filters="{}"
                :initializeItems="true"
                :itemsList="bind.arrayContaBancaria"
                rules="required"
                @input="replicarParcelamento"
              />

              <ed-input-select
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-2 pl-2"
                v-model="formData.movimentos[0].intParametroFormaPagamentoId"
                :disabled="
                  !formData.intDestinoId || bind.boolLancamentoPago
                    ? true
                    : false
                "
                label="Forma de Pagamento"
                name="intParametroFormaPagamentoId"
                :items="bind.arrayFormaPagamento"
                rules="required"
                @input="replicarParcelamento"
              >
                <template slot="append">
                  <ed-button
                    @click="exibirFormaPagamento"
                    :iconLeft="$utilidade.getIcone('cadastrar')"
                    class="mt-0 pa-0"
                    onlyIcon
                    color="success"
                    small
                    text
                  />
                </template>
              </ed-input-select>

              <!-- <ed-input-checkbox
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-2 pl-2"
          v-model="formData.boolValorDiferentePorParcela"
          label="Valor diferente por parcela"
          name="boolValorDiferentePorParcela"
          :disabled="
            formData.movimentos[0].boolPagamentoCancelado ? true : false
          "
        /> -->

              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0 pr-0 pl-2"
                v-if="formData.movimentos.length <= 1"
              >
                <table>
                  <tr>
                    <td>
                      <ed-input-checkbox
                        @input="marcarPago(formData.movimentos[0])"
                        v-model="formData.movimentos[0].boolPagamentoEfetuado"
                        label="Marcar como pago"
                        name="boolPagamentoEfetuado"
                        :disabled="
                          formData.movimentos[0].boolPagamentoCancelado
                            ? true
                            : false
                        "
                      />
                    </td>
                    <td class="pl-10 pl-2">
                      <ed-input-checkbox
                        v-model="
                          formData.movimentos[0].boolPagamentoCancelado
                        "
                        :disabled="
                          formData.movimentos[0].boolPagamentoEfetuado
                            ? true
                            : false
                        "
                        label="Cancelar Pagamento"
                        name="boolPagamentoCancelado"
                      />
                    </td>
                  </tr>
                </table>
              </div>

              <div
                class="col pt-0 pr-0 pl-2"
                v-if="formData.movimentos.length <= 1"
              ></div>

              <ed-recebimento
                class="col-12"
                :formData="formData.movimentos[0]"
                :disabled="
                  !formData.intDestinoId || bind.boolLancamentoPago
                    ? true
                    : false
                "
                v-if="
                  formData.movimentos[0].boolPagamentoEfetuado &&
                  formData.movimentos.length <= 1
                "
              />

              <div
                class="col-12 ma-3 text-center"
                v-if="
                  formData.movimentos[0].boolPagamentoEfetuado &&
                  formData.movimentos.length <= 0
                "
              >
                <h4 style="color: green">
                  <i :class="$utilidade.getIcone('checkbox')"></i>
                  Pagamento Recebido
                </h4>
              </div>

              <div
                class="col-12 ma-3 text-center"
                v-else-if="
                  formData.movimentos[0].boolPagamentoCancelado &&
                  formData.movimentos.length <= 0
                "
              >
                <h4 style="color: red">
                  <i :class="$utilidade.getIcone('cancelar')"></i>
                  Pagamento Cancelado
                </h4>
              </div>

              <div
                class="col-12 ma-3 text-center"
                v-else-if="
                  formData.movimentos[0].strDataVencimento &&
                  formData.movimentos.length <= 0 &&
                  $utilidade.diffDays(
                    formData.movimentos[0].strDataVencimento,
                    $utilidade.toDate(null, true, 'YYYY-MM-DD'),
                    'days'
                  ) < 0
                "
              >
                <h4 style="color: orange">
                  <i :class="$utilidade.getIcone('risco')"></i> Pagamento
                  Vencido
                </h4>
              </div>

              <div
                class="col-12 ma-3 text-center"
                v-else-if="
                  formData.movimentos[0].strDataVencimento &&
                  formData.movimentos.length <= 0 &&
                  $utilidade.diffDays(
                    formData.movimentos[0].strDataVencimento,
                    $utilidade.toDate(null, true, 'YYYY-MM-DD'),
                    'days'
                  ) >= 0
                "
              >
                <h4 style="color: #275791">
                  <i :class="$utilidade.getIcone('risco')"></i> Pagamento
                  Pendente
                </h4>
              </div>
              <div
                class="col-12 mb-2"
                v-if="formData.movimentos.length > 1"
              ></div>
            </template>
          </div>
        </template>
        <template v-if="formData.intId && formData.movimentos.length <= 1">
          <ed-pagamento
            :key="
              formData.movimentos[0].intId + strTab + $root.$session.versao
            "
            :objMovimento="formData.movimentos[0]"
            v-if="formData.movimentos[0] && strTab == 'tab-boleto'"
          />
          <ed-nfse
            :key="
              formData.movimentos[0].intId + strTab + $root.$session.versao
            "
            :intMovimentoId="formData.movimentos[0].intId"
            v-if="formData.movimentos[0] && strTab == 'tab-nfse'"
          />
          <ed-listar-cobranca
            :key="
              formData.movimentos[0].intId + strTab + $root.$session.versao
            "
            :objCabecalhoMovimento="formData"
            :objMovimento="formData.movimentos[0]"
            v-if="formData.movimentos[0] && strTab == 'tab-cobranca'"
          />
          <ed-listar-anexo
            :key="
              formData.movimentos[0].intId + strTab + $root.$session.versao
            "
            :objCabecalhoMovimento="formData"
            :objMovimento="formData.movimentos[0]"
            v-if="formData.movimentos[0] && strTab == 'tab-anexo'"
          />
        </template>
      </v-card-text>
    </v-card>

    <span class="form-title" v-if="formData.movimentos.length > 1"
      >Informações do parcelamento</span
    >

    <template v-for="(parcela, index) in formData.movimentos">
      <v-card
        :key="'card-' + index"
        class="mb-5 mt-3"
        style="border: solid 1px #66666"
        v-if="formData.movimentos.length > 1"
      >
        <v-card-title
          @click="parcela.boolExibir = !parcela.boolExibir"
          class="ed-mouse-hand"
          :key="'title-' + index + '-' + parcela.boolExibir"
        >
          <div style="text-align: left; width: 60%; float: left">
            Parcela {{ index + 1 }}
          </div>
          <div style="text-align: right; width: 40%; float: rith">
            <i
              :class="
                !parcela.boolExibir
                  ? $utilidade.getIcone('setaBaixo')
                  : $utilidade.getIcone('setaCima')
              "
            ></i>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div
            style="color: green; margin-top: 10px"
            :key="'pagamentook-' + index"
            v-if="parcela.boolPagamentoEfetuado"
          >
            <i :class="$utilidade.getIcone('checkbox')"></i> Pagamento Recebido
          </div>
          <div
            :key="'pagamentonok-' + index"
            style="color: red; margin-top: 10px"
            v-else-if="parcela.boolPagamentoCancelado"
          >
            <i :class="$utilidade.getIcone('cancelar')"></i> Pagamento Cancelado
          </div>
          <div
            :key="'vencido-' + index"
            style="color: orange; margin-top: 10px"
            v-else-if="
              parcela.strDataVencimento &&
              $utilidade.diffDays(
                parcela.strDataVencimento,
                $utilidade.toDate(null, true, 'YYYY-MM-DD'),
                'days'
              ) < 0
            "
          >
            <i :class="$utilidade.getIcone('risco')"></i> Pagamento Vencido
          </div>
          <div
            :key="'pendente-' + index"
            style="color: #275791; margin-top: 10px"
            v-else-if="
              parcela.strDataVencimento &&
              $utilidade.diffDays(
                parcela.strDataVencimento,
                $utilidade.toDate(null, true, 'YYYY-MM-DD'),
                'days'
              ) >= 0
            "
          >
            <i :class="$utilidade.getIcone('pendente')"></i> Pagamento Pendente
          </div>
        </v-card-subtitle>

        <v-card-text class="pa-7 pt-0 pb-0" v-show="parcela.boolExibir">

          <v-tabs
            v-model="parcela.strTab"
            bg-color="deep-purple-accent-4"
            centered
            icons-and-text
          >
            <v-tab :href="'#tab-principal-' + index">
              <v-icon>mdi-phone</v-icon>
              Informações da Parcela
              <i :class="$utilidade.getIcone('detalhes') + ' fa-2x'"></i>
            </v-tab>

            <v-tab :href="'#tab-anexo-' + index">
              Anexos
              <i :class="$utilidade.getIcone('anexo') + ' fa-2x'"></i>
            </v-tab>
            <v-tab
              :href="'#tab-cobranca-' + index"
              v-if="
                formData.intId &&
                formData.movimentos.length <= 1 &&
                formData.intTipoMovimentoId == 1443
              "
            >
              Visualizar Cobranças
              <i :class="$utilidade.getIcone('servico') + ' fa-2x'"></i>
            </v-tab>
            <v-tab
              :href="'#tab-boleto-' + index"
              v-if="
                formData.intId &&
                formData.movimentos.length <= 1 &&
                formData.intTipoMovimentoId == 1443
              "
            >
              Gerar Boleto de Cobrança
              <i :class="$utilidade.getIcone('pdf') + ' fa-2x'"></i>
            </v-tab>

            <v-tab
              :href="'#tab-nfse-' + index"
              v-if="
                formData.intId &&
                formData.movimentos.length <= 1 &&
                formData.intTipoMovimentoId == 1443
              "
            >
              Gerar Nota Fiscal de Serviço
              <i :class="$utilidade.getIcone('xml') + ' fa-2x'"></i>
            </v-tab>
          </v-tabs>


          <template
            v-if="!parcela.strTab || parcela.strTab == 'tab-principal-' + index"
          >
            <div
              class="row"
              :key="'segundo-movimento-' + index"
              v-if="formData.movimentos.length > 1"
            >
              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-money
                  v-model="parcela.floatValor"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  @input="validarValoresParcela"
                  label="Valor"
                  name="floatValor"
                  rules="required"
                />
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-date
                  v-model="parcela.strDataVencimento"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Data Vencimento"
                  name="strDataVencimento"
                  rules=""
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-date
                  v-model="parcela.strDataCompentencia"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Data Competência"
                  name="strDataVencimento"
                  rules=""
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-auto-complete
                  v-model="parcela.intContaBancariaId"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Conta Bancária"
                  name="intContaBancariaId"
                  route="Configuracoes/ContaBancaria"
                  :filters="{}"
                  :initializeItems="true"
                  :itemList="bind.arrayContaBancaria"
                  rules="required"
                  disabledSync
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-select
                  v-model="parcela.intParametroFormaPagamentoId"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Forma de Pagamento"
                  name="intFormaPagamentoId"
                  :items="bind.arrayFormaPagamento"
                  rules="required"
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-money
                  v-model="parcela.floatValorDesconto"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Desconto"
                  name="floatValorDesconto"
                  rules=""
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-money
                  v-model="parcela.floatValorAcrescimento"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Acréscimo"
                  name="flaotValorAcrescimento"
                  rules=""
                />
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-2"
              >
                <ed-input-text
                  v-model="parcela.strDescricao"
                  :disabled="parcela.boolMovimentoBloqueado ? true : false"
                  label="Descrição"
                  name="strDescricao"
                  rules=""
                />
              </div>
            </div>
            <div
              class="row"
              :key="'movimento-DESCONTI-' + index"
              v-if="formData.movimentos.length > 1"
            >
              <div
                class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 pa-0 pr-2"
              >
                <ed-input-checkbox
                  v-model="parcela.boolPagamentoEfetuado"
                  @input="marcarPago(parcela)"
                  :disabled="
                    parcela.boolMovimentoBloqueado ||
                    parcela.boolPagamentoCancelado
                      ? true
                      : false
                  "
                  label="Marcar como pago"
                  name="intParametroStatusParcela"
                />
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-2 pa-0 pr-2"
              >
                <ed-input-checkbox
                  v-model="parcela.boolPagamentoCancelado"
                  :disabled="
                    parcela.boolMovimentoBloqueado ||
                    parcela.boolPagamentoEfetuado
                      ? true
                      : false
                  "
                  label="Cancelar Pagamento"
                  name="boolMovimentoBloqueado"
                />
              </div>
            </div>
            <div
              class="row"
              :key="'recebimento-' + index"
              v-if="
                formData.movimentos.length > 1 && parcela.boolPagamentoEfetuado
              "
            >
              <div class="col pa-0 pr-2">
                <ed-recebimento :formData="parcela" />
              </div>
            </div>
          </template>

          <ed-pagamento
            :key="index + parcela.strTab + $root.$session.versao"
            :objMovimento="parcela"
            v-if="parcela.strTab == 'tab-boleto-' + index"
          />
          <ed-listar-cobranca
            :key="index + parcela.strTab + $root.$session.versao"
            :objCabecalhoMovimento="formData"
            :objMovimento="parcela"
            v-if="parcela.strTab == 'tab-cobranca-' + index"
          />
          <ed-listar-anexo
            :key="index + parcela.strTab + $root.$session.versao"
            :objCabecalhoMovimento="formData"
            :objMovimento="parcela"
            v-if="parcela.strTab == 'tab-anexo-' + index"
          />
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputText,
  EdInputSelect,
  EdInputDate,
  EdInputCheckbox,
  EdInputMoney,
  EdInputAutoComplete,
  EdButton,
} from "@/components/common/form";
import EdRecebimento from "./recebimento.vue";
import EdCobranca from "./cobranca/index.vue";
import EdPagamento from "./cobranca/pagamento.vue";
import EdNfse from "@/components/notaFiscal/index";
import EdListarCobranca from "./cobranca/listar.vue";
import EdListarAnexo from "./cobranca/anexo.vue";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputText,
    EdInputSelect,
    EdInputDate,
    EdInputCheckbox,
    EdInputMoney,
    EdInputAutoComplete,
    EdRecebimento,
    EdCobranca,
    EdPagamento,
    EdNfse,
    EdListarCobranca,
    EdListarAnexo,
    EdButton,
  },
  inject: [
    "bind",
    "setStatusFinanceiro",
    "formatarParcelamento",
    "replicarParcelamento",
    "validarValoresParcela",
    "isMovimentoFinalizado",
    "exibirFormaPagamento",
    "calcularServico",
  ],
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "tab-principal",
      boolModalCobranca: false,
      boolExibirCadastroCategoria: false,
      formCobranca: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {},

    marcarPago(item) {
      if (item.boolPagamentoEfetuado) {
        item.strDataRecebimento = this.$utilidade.toDate(null, true);
        item.floatValorRecebido = item.floatValor;
      } else {
        item.strDataRecebimento = null;
        item.floatValorRecebido = null;
      }
    },
  },
};
</script>
