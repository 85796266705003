<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-anexo">
        Anexos
        <i :class="$utilidade.getIcone('anexo') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-cobranca" v-if="objCabecalhoMovimento.intTipoMovimentoId == 1443">
        Enviar Cobranças por Email
        <i :class="$utilidade.getIcone('enviar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-boleto" v-if="objCabecalhoMovimento.intTipoMovimentoId == 1443">
        Gerar Boleto de Cobrança
        <i :class="$utilidade.getIcone('pdf') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-nfse" v-if="objCabecalhoMovimento.intTipoMovimentoId == 1443 && $root.$session.verificarPermissaoModulo(5) && $root.$session.verificarPermissaoMenu('nfse.emitir')">
        Gerar Nota Fiscal de Serviço
        <i :class="$utilidade.getIcone('xml') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>
    ed-listar-cobranca

    <ed-pagamento :key="objMovimento.intId+strTab"  :objMovimento="objMovimento" v-if="!this.$root.$session.loadingContent && objMovimento && strTab == 'tab-boleto'" />
    <ed-nfse :key="objMovimento.intId+strTab"  :intMovimentoId="objMovimento.intId" v-if="!this.$root.$session.loadingContent && objMovimento && strTab == 'tab-nfse'" />
    <ed-listar-cobranca :key="objMovimento.intId+strTab"  :objCabecalhoMovimento="objCabecalhoMovimento" :objMovimento="objMovimento" v-if="!this.$root.$session.loadingContent && objMovimento && strTab == 'tab-cobranca'" />
    <ed-listar-anexo :key="objMovimento.intId+strTab" :objCabecalhoMovimento="objCabecalhoMovimento" :objMovimento="objMovimento" v-if="!this.$root.$session.loadingContent && objMovimento && strTab == 'tab-anexo'" />
  </div>
</template>

<script>

import EdPagamento from './pagamento.vue'
import EdNfse from "@/components/notaFiscal/index";
import EdListarCobranca from './listar.vue'
import EdListarAnexo from './anexo.vue'


export default {
  name: "pageCadastroEmpresa",
  props: {
    objCabecalhoMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    objMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdPagamento,
    EdNfse,
    EdListarCobranca,
    EdListarAnexo
  },
  inject: [],
  mounted() {
    this.initialize()
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "tab-anexo",
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
    },
  },
};
</script>
