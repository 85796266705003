<template>
  <div class="">
   
  
  </div>
</template>

<script>


export default {
  name: "pageCadastroEmpresa",
  props: {
    intModel: {
      type: [String, Number],
      required: true,
      default: null,
    },
    strModel: {
      type: [String],
      required: true,
      default: null,
    }
  },
  components: {
  },
  mounted() {

    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  created() {},
  data() {
    return {
      formFiltros: {
        intModel: this.intModel,
        strModel: this.strModel,
        boolEstatistica:true,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
    };
  },
  watch: {
    intModel() {
      this.initialize();
    },
    strModel() {
      this.initialize();
    },
  },
  methods: {

    initialize() {
      this.getLogEmail();
    },

    getLogEmail() {
      if (!this.intModel) return;

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Email", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
          }
        });
    },

  },
};
</script>
