var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ed-log-email',{attrs:{"intModel":_vm.objMovimento.intId,"strModel":"Movimento","strAssunto":_vm.formData.strTitulo,"intTipoServicoContaEmailId":330,"arrayAnexo":[
      {
        strNomeMetodo:'getAnexoBoleto',
        strLabel:'Anexar Boleto',
        strField:'boolAnexarBoleto',
        boolAtivo:0
      },
      {
        strNomeMetodo:'getAnexoNfse',
        strLabel:'Anexar Nota Fiscal de Serviço',
        strField:'boolAnexarNfse',
        boolAtivo:0
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }