<template>
  <div v-if="formData && formData.strLog">
    <ed-simple-table disabledRegisterRows disabledSearchRows>
      <thead>
        <tr>
          <th width="170">Data</th>
          <th>Email</th>
          <th>Mensagem</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(objEnvio, index) in this.formData.strLog" :key="index">
          <td>
            {{objEnvio.strDataCadastro ? $utilidade.toDate(objEnvio.strDataCadastro) : '--'}}
          </td>
          <td>{{objEnvio.strEmail ? objEnvio.strEmail : '--'}}</td>
          <td>
            <span v-if="objEnvio.strMensagem">{{ objEnvio.strMensagem }}</span>
            <span v-else>{{ objEnvio }}</span>
          </td>
        </tr>
        <tr v-if="!this.formData.strLog.length">
          <td colspan="3" align="center">Nenhuma ocorrência encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdSimpleTable,
  },
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "error",
    };
  },
  watch: {},
  methods: {},
  computed: {
  },
};
</script>
