<template>
  <div class="row mt-5 mb-4">
    <div :class="'row' + (!boolDisableEstatistica ? '' : '')">
      <ed-historico
        v-if="boolDisableEstatistica && this.formData"
        :key="'cadastro-' + $root.$session.versao"
        :formData="formData"
        :boolDisableEnvioEmail="boolDisableEnvioEmail"
      >
      </ed-historico>

      <ed-estatistica
        v-if="!boolDisableEstatistica && this.formData"
        :key="'cadastro-' + $root.$session.versao"
        :formData="formData"
      />
    </div>
  </div>
</template>

<script>
import EdHistorico from "./partials/historico.vue";
import EdEstatistica from "./partials/estatistica.vue";

export default {
  name: "pageCadastroEmpresa",
  props: {
    intModel: {
      type: [String, Number],
      required: false,
      default: null,
    },
    strModel: {
      type: [String],
      required: false,
      default: null,
    },
    strTemplate: {
      type: [String],
      required: false,
      default: null,
    },
    strAssunto: {
      type: [String],
      required: false,
      default: null,
    },
    strMensagem: {
      type: [String],
      required: false,
      default: null,
    },
    strDestinatarioEmail: {
      type: [String],
      required: false,
      default: null,
    },
    strIdentificador:{
      type: [String],
      required: false,
      default: null,
    },
    strNomeDatabase:{
      type: [String],
      required: false,
      default: null,
    },
    intTipoServicoContaEmailId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    arrayAnexo: {
      type: [Array],
      required: false,
      default: null,
    },
    boolDisableEstatistica: {
      type: [Boolean],
      required: false,
      default: true,
    },
    boolDisableEnvioEmail: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdHistorico,
    EdEstatistica,
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  provide() {
    return {};
  },
  created() {},
  data() {
    return {
      strTab: this.boolDisableEstatistica ? "tab-historico" : "tab-estatistica",
      formData: null,
    };
  },
  watch: {
    intModel() {
      this.initialize()
    },
    strModel() {},
    intTipoServicoContaEmailId() {},
  },
  methods: {
    initialize() {
      this.formData = {
        intId: 0,
        intContaEmailId: null,
        intModel: this.intModel,
        strModel: this.strModel,
        strMensagem: this.strMensagem,
        strAssunto: this.strAssunto,
        strDestinatarioEmail: this.strDestinatarioEmail,
        intTipoServicoContaEmailId: this.intTipoServicoContaEmailId,
        strIdentificador:this.strIdentificador,
        strNomeDatabase:this.strNomeDatabase,
        strAnexo: this.arrayAnexo,
      };
    },
  },
};
</script>
