<template>
  <div>
    <ed-modal v-model="boolModalCobranca" width="50%">
      <div slot="title">
        Gerenciar Cobranca::
      </div>

      <div class="" slot="content">
        <ed-form :formData="formData" :handlerSave="salvar">
          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.arquivo"
            name="arquivo"
            label="Arquivo"
            rules=""
          />

          <ed-input-text-area
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strDescricao"
            name="strDescricao"
            label="Descrição"
            rules="required"
          />
        </ed-form>
      </div>
    </ed-modal>
    <ed-table
    :key="$root.$session.versao"
      class="mt-5"
      strModel="Movimento/MovimentoAnexo"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :config="{ registerName: 'Inserir Anexo' }"
      @register="onRegister"
      @search="getAnexo"
      disabledEditRows
      disabledDeleteRows
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdForm,
  EdModal,
  EdInputFile,
  EdInputTextArea,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    objMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    objCabecalhoMovimento:{
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    }
  },
  components: {
    EdTable,
    EdForm,
    EdModal,
    EdInputFile,
    EdInputTextArea,
  },
  inject: [],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      loading: false,
      boolModalCobranca: false,
      formFiltros: {},
      formData: {
        intId: 0,
        strDescricao: null,
        arquivo:null,
        strDestinatario: null,
      },
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
        },
        {
          text: "Descrição",
          sortable: true,
          value: "strDescricao",
        },
        {
          text: "Anexo",
          sortable: true,
          showArquivo:true,
          value: "arquivo",
        },
        {
          text: "Data Cadastro",
          sortable: true,
          value: "strDataCadastro",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onRegister() {
      this.boolModalCobranca = !this.boolModalCobranca;
    },

    initialize() {
      this.getAnexo()
    },

    getAnexo() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Movimento/MovimentoAnexo", {
          intMovimentoId: this.objMovimento.intId,
        })
        .then((objResult) => {
          this.objPagination = objResult.result;
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strDescricao: objModel.strDescricao,
                strDataCadastro: this.$utilidade.toDate(
                  objModel.strDataCadastro,
                  false
                ),
                buttons: [],
                _item: objModel,
              };

              // if (
              //   objModel.forma_pagamento.strChave ==
              //     "financeiroFormaPagamentoBoleto" &&
              //   ["pagamentoStatusBoletoGerado"].indexOf(
              //     objModel.status.strChave
              //   ) != -1
              // ) {
              //   item.buttons.push({
              //     icone: this.$utilidade.getIcone("pdf"),
              //     color: "warning",
              //     title: "Download do Boleto",
              //     click: this.downloadBoleto,
              //   });
              // }
              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.formData.intCabecalhoMovimentoId = this.objCabecalhoMovimento.intId;
      this.formData.intMovimentoId = this.objMovimento.intId;

      this.$root.$request
        .post("Movimento/MovimentoAnexo", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getAnexo()
            this.boolModalCobranca = !this.boolModalCobranca;
          }
        });
    },
  },
};
</script>
