<template>
  <div>
    <!--EDITAR OUTRAS INFORMAÇÕES EXAME-->
    <ed-servico-editar
      v-if="formFiltros.boolExibirModalEdit"
      :formData="formData"
      :formFiltros="formFiltros"
      :key="$root.$session.versao"
    />

    <ed-simple-table
      class="mb-3"
      v-if="formData"
      :config="{
        registerName: 'Inserir novo serviço',
        boolDisableRegister:
          !formData.intDestinoId ||
          bind.boolLancamentoBloqueado 
            ? true
            : false,
      }"
      @register="onRegister"
      disabledSearchRows
    >
      <template v-for="(servicos, strChave) in getServicoFormatado()">
        <thead
          :key="'thead' + strChave"
          v-if="strChave == 'financeiroServicoExamesComplementares'"
        >
          <tr>
            <th style="background: #fcfcd4" colspan="6">{{ servicos[0].servico.strNome }}</th>
          </tr>
          <tr>
            <th width="150">DATA</th>
            <th width="150">VALOR</th>
            <th width="150">CONSULTA</th>
            <th>EXAME</th>
            <th>FUNCIONÁRIO</th>
            <th width="120"></th>
          </tr>
        </thead>
        <thead
          :key="'thead' + strChave"
          v-else-if="strChave != 'financeiroServicoExamesComplementares'"
        >
          <tr>
            <th  style="background: #fcfcd4" colspan="6">{{ servicos[0].servico.strNome }}</th>
          </tr>
          <tr>
            <th width="250">DATA</th>
            <th width="250">VALOR</th>
            <th></th>
            <th></th>
            <th></th>
            <th width="120"></th>
          </tr>
        </thead>
        <tbody :key="'tbody' + strChave">
          <template v-for="(servico, index) in servicos">
            <tr :key="'tr-' + index">
              <td>
                <ed-input-date
                  v-model="servico.strDataServico"
                />
              </td>
              <td>
                <ed-input-money
                  v-model="servico.floatValor"
                  @input="alterarValorServico(servico)"
                />
              </td>
              <td>
                <span class="ed-table-info ed-full-width" v-if="servico.point">
                  <a
                    target="_blank"
                    :href="
                      $root.$session.urlSistema +
                      '/consulta/editar/' +
                      servico.point.intConsultaId
                    "
                  >
                    <i :class="$utilidade.getIcone('consulta')"></i>
                    {{ servico.point.intConsultaId }}
                  </a>
                </span>
              </td>
              <td>
                <span
                  title="Exame"
                  class="ed-table-info ed-full-width"
                  v-if="servico.exame"
                >
                  <a
                    target="_blank"
                    :href="
                      $root.$session.urlSistema +
                      '/cadastro/exame/editar/' +
                      servico.exame.intId
                    "
                  >
                    <i :class="$utilidade.getIcone('exame')"></i>
                    {{ servico.exame.strNome }}
                  </a>
                </span>
              </td>
              <td>
                <span
                  title="Funcionário"
                  class="ed-table-info ed-full-width"
                  v-if="servico.funcionario"
                >
                  <a
                    target="_blank"
                    :href="
                      $root.$session.urlSistema +
                      '/cadastro/funcionario/editar/' +
                      servico.funcionario.intId
                    "
                  >
                    <i :class="$utilidade.getIcone('funcionario')"></i>
                    {{ servico.funcionario.strNome }}
                  </a>
                </span>
              </td>
              <td>
                <ed-button
                  @click="removerServico(index)"
                  :iconLeft="$utilidade.getIcone('deletar')"
                  :disabled="
                    bind.boolLancamentoBloqueado || bind.boolLancamentoPago
                      ? true
                      : false
                  "
                  icon
                  onlyIcon
                  title="Remover serviço"
                  color="error"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </template>
      <template v-if="formData.servicos.length">
        <tbody>
          <tr>
            <th>Sub Total</th>
            <th>
              R$ {{ $utilidade.floatToMoney(formData.floatTotalMovimento) }}
            </th>

            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr v-if="getTotalDescontoParcela()">
            <th>Descontos</th>
            <th>
              R$ {{ $utilidade.floatToMoney(getTotalDescontoParcela()) }}
            </th>

            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr v-if="getTotalAcrescimoParcela()">
            <th>Acrescimo</th>
            <th>
              R$ {{ $utilidade.floatToMoney(getTotalAcrescimoParcela()) }}
            </th>

            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr :style="'color:'+(intTipoMovimentoId == 1443 ? 'green' : 'red')">
            <th>
              <span v-if="intTipoMovimentoId == 1443 ">Total a Receber</span>
              <span v-if="intTipoMovimentoId == 1444 ">Total a Pagar</span>
            </th>
            <th >
              R$ {{ $utilidade.floatToMoney(getTotalMovimento()+getTotalAcrescimoParcela()-getTotalDescontoParcela()) }}
            </th>

            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tbody>
      </template>
    </ed-simple-table>


    <!-- <div
      class="row d-flex justify-content-center"
      v-if="bind.boolLancamentoBloqueado"
    >
      <ed-alert class="col-6 mt-4 mb-5" title="Atenção" type="warning"
        >Não é possível alterar um lançamento com pagamentos
        efetudos/cancelados/excluídos ou com cobrança gerado. Para realizar essa
        alteração é necessário cadastrar outro lançamento.</ed-alert
      >
    </div> -->
  </div>
</template>

<script>
import {
  EdForm,
  EdSimpleTable,
  EdInputMoney,
  EdInputDate,
  EdModal,
  EdAlert,
  EdButton,
} from "@/components/common/form";

import EdServicoEditar from "./servico-editar";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdForm,
    EdSimpleTable,
    EdInputMoney,
    EdInputDate,
    EdModal,
    EdAlert,
    EdButton,
    EdServicoEditar,
  },
  inject: [
    "bind",
    "exibirCadastroServico",
    "intTipoMovimentoId",
    "getTotalMovimento",
    "getPercentualDesconto",
    "getPercentualAcrescimo",
    "getTotalDescontoParcela",
    "getTotalAcrescimoParcela",
    "formatarParcelamento",
    "calcularServico"
  ],
  provide() {
    return {
      bind: this.bind,
      calcularServico: this.calcularServico,
      formatarParcelamento: this.formatarParcelamento,
    };
  },
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {
      formFiltros: {
        boolExibirModalEdit: false,
        intDestinoId: this.formData.intDestinoId,
        boolBuscarServicoFaturado: 1,
        floatTotal: 0,
        intTotalServico: 0,
        boolSelecionarTudo: false,
        arrayDataCadastro: [
          this.$utilidade.getInicioMes(),
          this.$utilidade.getFimMes(),
        ],
        intCadastroGeralUnidadeId: null,
        intCadastroGeralSetorId: null,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {},

    onRegister() {
      this.formFiltros.boolExibirModalEdit =
        !this.formFiltros.boolExibirModalEdit;
    },

    getServicoFormatado() {
      let objServico = {};

      this.formData.servicos.forEach((element,index) => {
        let strChave = "";
        if (element.servico) {
          strChave = element.servico.strChave;
        } else {
          strChave = "avulso";
        }

        if (!objServico[strChave]) {
          objServico[strChave] = [];
        }

        element.posicao = index
        objServico[strChave].push(element);
      });

      return objServico;
    },

    alterarValorServico(servico){

      if(this.formData.servicos[servico.posicao]){
        this.formData.servicos[servico.posicao].floatValor = servico.floatValor
        this.formData.servicos[servico.posicao].strDataServico = servico.strDataServico
      }
      this.calcularServico()
    },



    removerServico(index) {
      if (!this.bind.boolLancamentoBloqueado) {
        this.formData.servicos.splice(index, 1);
        this.calcularServico();
      }
    },
  },
};
</script>
