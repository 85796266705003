<template>
  <div>
    <!--DETALHES DO BOLETO-->
    <ed-modal v-model="bind.boolExibirDetalhesBoleto" width="60%">
      <div slot="title" v-if="formData">
        Detalhes do boleto Número {{ formData.intId }}
      </div>

      <div class="" slot="content" v-if="formData">
        <ed-log-alteracao :formData="{'intModel':formData.intId,'strModel':'MovimentoPagamento'}" :headers="bind.arrayHeadersLog" :key="$root.$session.versao"/>
        <!-- <ed-simple-table disabledSearchRows disabledRegisterRows>
          <thead>
            <tr>
              <th>Data Cadastro</th>
              <th>Data Vencimento</th>
              <th>Valor</th>
              <th>Usuário</th>
              <th>Observações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in formData.strLog">
              <tr v-if="item && item.strDataCadastro" :key="index">
                <td>
                  {{ $utilidade.toDate(item.strDataCadastro) }}
                </td>
                <td>
                  {{ $utilidade.toDate(item.strDataVencimento, true) }}
                </td>
                <td>
                  {{ $utilidade.floatToMoney(Number(item.floatValor)) }}
                </td>
                <td>
                  {{ item.usuario ? item.usuario.strLogin : "" }}
                </td>
                <td>
                  {{ item.strObservacao }}
                </td>
              </tr>
            </template>
          </tbody>
        </ed-simple-table> -->
      </div>
    </ed-modal>
    <ed-table
      :key="$root.$session.versao"
      class="mt-5"
      strModel="Movimento/MovimentoPagamento"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :config="{ registerName: 'Gerar novo boleto' }"
      @register="salvar"
      @search="initialize"
      disabledEditRows
      disabledSearchRows
    />
  </div>
</template>

<script>
import { EdTable, EdModal } from "@/components/common/form";
import EdLogAlteracao from "@/components/common/log/alteracao/index";

export default {
  name: "pageCadastroEmpresa",
  props: {
    objMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdTable,
    EdModal,
    EdLogAlteracao,
  },
  inject: [],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      loading: false,
      formFiltros: {},
      formData: null,
      bind: {
        boolExibirDetalhesBoleto: false,
        arrayHeadersLog:[
          {
            text: "Data Vencimento",
            sortable: true,
            value: "strDataVencimento",
          },
          {
            text: "Valor",
            sortable: true,
            value: "floatValor",
          },
          {
            text: "Observações",
            sortable: true,
            value: "strObservacao",
          },
        ]
      },
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
        },
        {
          text: "Conta Bancária",
          sortable: true,
          value: "strConta",
        },
        {
          text: "Forma Pagamento",
          sortable: true,
          value: "strFormaPagamento",
        },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Movimento/MovimentoPagamento", {
          intMovimentoId: this.objMovimento.intId,
        })
        .then((objResult) => {
          this.objPagination = objResult.result;
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strConta:
                  '<img src="' +
                  objModel.conta.strImagem +
                  '"> ' +
                  objModel.conta.strNome,
                strFormaPagamento: objModel.forma_pagamento.strNome,
                strStatus: objModel.status.strNome,
                disableDelete:['financeiroStatusPagamentoCancelado','financeiroStatusBoletoAguardandoRegistro'].indexOf(objModel.status.strChave) != -1,
                buttons: [],
                _item: objModel,
              };

              if (
                objModel.forma_pagamento.strChave ==
                  "financeiroFormaPagamentoBoleto" &&
                [
                  "pagamentoStatusBoletoGerado",
                  "financeiroStatusBoletoGerado",
                ].indexOf(objModel.status.strChave) != -1
              ) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("pdf"),
                  color: "warning",
                  title: "Download do Boleto",
                  click: this.downloadBoleto,
                });
              }

              item.buttons.push({
                icone: this.$utilidade.getIcone("logs"),
                color: "info",
                title: "Histórico de alterações do boleto",
                click: this.detalhesBoleto,
              });

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    detalhesBoleto(item) {
      this.bind.boolExibirDetalhesBoleto = !this.bind.boolExibirDetalhesBoleto;
      this.formData = Object.assign(item, {});
    },

    downloadBoleto(item) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Movimento/MovimentoPagamento", {
          boolDownloadBoleto: true,
          intId: item.intId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.href =
              "data:text/plain;base64," + objResponse.result[0].strConteudoArquivo;
            link.download = "Boleto_" + item.intId + ".pdf";
            link.click();
            document.body.removeChild(link);
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Movimento/MovimentoPagamento", {
          intId: 0,
          intMovimentoId: this.objMovimento.intId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.initialize();

          if (objResponse.status == 200) {
          }
        });
    },
  },
};
</script>
