<template>
  <div class="mb-6">
    <!--NOVO EMAIL-->
    <ed-modal v-model="bind.boolExibirCadastro" width="70%">
      <div slot="title">Enviar Novo Email</div>

      <div class="" slot="content">
        <ed-form :formData="formData" :handlerSave="salvar" disableFixedSave>
          <div class="row ma-0 pa-0">
            <div
              class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-left"
            >
              <span class="form-title">Informações do Envio</span>

              <div class="row mb-3">
                <ed-input-auto-complete
                  v-if="!formData.intTipoServicoContaEmailId"
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  v-model="formData.intContaEmailId"
                  label="Selecione uma conta de email"
                  name="intContaEmailId"
                  route="Configuracoes/ContaEmail"
                  rules="required"
                />

                <ed-input-text
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
                  v-model="formData.strDestinatarioEmail"
                  label="Email do Destinatário"
                  name="strDestinatarioEmail"
                  rules="required|email"
                  info="Para adicionar varios emaisl separe com `;`. Exemplo: email1@teste.com;email2@teste.com"
                />

                <ed-input-editor
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
                  v-model="formData.strMensagem"
                  label="Mensagem"
                  name="strMensagem"
                  rules="required"
                />
              </div>

              <template v-if="formData.strAnexo && formData.strAnexo.length">
                <span class="form-title">Outras Informações</span>
                <div class="row">
                  <template v-for="(objAnexo, index) in formData.strAnexo">
                    <ed-input-checkbox
                      :key="'anexo-' + index"
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-0 pt-0"
                      v-model="objAnexo.boolAtivo"
                      :name="objAnexo.strField"
                      :label="objAnexo.strLabel"
                      rules=""
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <!--LOGS-->
    <ed-modal v-model="bind.boolExibirLog" width="70%">
      <div slot="title">Logs</div>

      <div class="" slot="content">
        <ed-log :formData="formDataLog" v-if="formDataLog" />
      </div>
    </ed-modal>

    <ed-table
      strModel="Common/LogEmail"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :disabledFilters="false"
      @search="getEmail"
      @filter="getEmail"
      @register="onRegister"
      disabledEditRows
      disabledDeleteRows
      :disabledRegisterRows="boolDisableEnvioEmail ? true: false"
      :disabledSearchRows="boolDisableEnvioEmail ? true: false"
      :config="{ registerName: 'Enviar Novo Email' }"
    >
      <template slot="table-filter">
        <ed-input-auto-complete
          v-if="!formData.intModel"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.strNomeDatabase"
          label="Cliente"
          name="strNomeDatabase"
          item-text="strNome"
          item-value="strNomeDatabase"
          route="System/Cliente"
          :filters="{}"
          clearable
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data da Ocorrência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />

        <!-- <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intCadastroGeralEmpresaId"
          label="Empresa"
          name="intCadastroGeralEmpresaId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{ intTipoCadastroGeralId: 58 }"
          clearable
        /> -->

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayStatus"
          label="Status"
          name="arrayStatus"
          :items="bind.arrayStatus"
          clearable
          multiple
        />
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputTipo,
  EdInputSelect,
  EdInputDate,
  EdInputText,
  EdInputEditor,
  EdForm,
} from "@/components/common/form";
import EdLog from "./log.vue";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    boolDisableEnvioEmail: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputTipo,
    EdInputSelect,
    EdInputDate,
    EdInputText,
    EdInputEditor,
    EdForm,
    EdLog,
  },
  mounted() {
    let self = this;
    this.$root.$socket.subscribe("email", "user", function (e) {
      self.initialize();
    });

    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
    //this.$root.$socket.unsubscribe("email");
  },
  provide() {
    return {};
  },
  created() {
  },
  data() {
    return {
      loading: false,
      bind: {
        boolExibirCadastro: false,
        boolExibirLog: false,
        intTotalFiltrosApliados: 1,
        arrayEventos: [],
        arrayStatus: [
          { intId: 0, strNome: "PENDENTE" },
          { intId: 1, strNome: "PROCESSANDO" },
          { intId: 2, strNome: "ENVIADO" },
          { intId: 3, strNome: "VISUALIZADO" },
          { intId: 4, strNome: "ENVIADO PARCIALMENTE" },
          { intId: 5, strNome: "FALHA" },
        ],
      },
      formFiltros: {
        intModel: this.formData.intModel,
        strModel: this.formData.strModel,
        strIdentificador:this.formData.strIdentificador,
        strNomeDatabase: this.formData.strNomeDatabase,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
      formDataLog: null,
      arrayHeaders: [
        {
          text: "Remetente",
          sortable: true,
          value: "strRemetenteEmail",
        },
        {
          text: "Destinatário",
          sortable: true,
          value: "strDestinatario",
        },
        {
          text: "Assunto",
          sortable: true,
          value: "strAssunto",
        },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
        {
          text: "Data Atualização",
          sortable: true,
          value: "strDataAtualizacao",
        },
      ],
      arrayRows: [],
      arrayTabs: [
        { strNome: "Estatíscas", strIcone: "estatistica" },
        { strNome: "Histórico de envio", strIcone: "historico" },
      ],
      strTab: "tab-0",
      objPagination: null,
    };
  },
  watch: {
    intModel() {
      this.initialize();
    },
    strModel() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.getEmail();
    },

    onRegister() {
      this.bind.boolExibirCadastro = true;
    },

    visualizarMensagem(data) {
      this.$root.$request.open("System/Email/get", {
        _id: data._id,
        strNomeDatabase:this.formData.strNomeDatabase,
        boolVisualizarMensagem: true,
      });
    },

    visualizarLog(data) {
      this.bind.boolExibirLog = true;
      this.formDataLog = Object.assign(data, {});
    },

    getEmail() {
      //if (!this.formData.intModel || !this.formData.strModel) return;

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Email", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let arrayDestinatario = [];
              let strStatus = "PENDENTE";
              let arrayInfo = [];

              if (objModel.strDestinatarioNome) {
                arrayDestinatario.push(objModel.strDestinatarioNome);
              }
              arrayDestinatario.push(objModel.strDestinatarioEmail);

              if (objModel.intStatus == 1) {
                strStatus = "PROCESSANDO";
              } else if (objModel.intStatus == 2) {
                strStatus = "ENVIADO";
              } else if (objModel.intStatus == 3) {
                strStatus = "VISUALIZADO";
              } else if (objModel.intStatus == 4) {
                strStatus = "ENVIADO PARCIALMENTE";
              } else if (objModel.intStatus == 5) {
                strStatus = "FALHA";
              }

              if (!this.formData.intModel) {
                arrayInfo.push(
                  "<b>Model:</b> " +
                    objModel.intModel +
                    " - " +
                    objModel.strModel
                );

                if (objModel.strAnexo && objModel.strAnexo.length) {
                  let arrayAnexo = [];
                  objModel.strAnexo.forEach((element) => {
                    if (element.strNomeArquivo) {
                      arrayAnexo.push(element.strNomeArquivo);
                    }
                  });

                  if (arrayAnexo.length){
                    arrayInfo.push("<b>Anexo:</b> " + arrayAnexo.join(", "));
                  }
                }
              }

              let item = {
                intId: objModel._id,
                strRemetenteEmail:
                  objModel.strRemetenteEmail + "<br>" + arrayInfo.join("<br>"),
                strAssunto: objModel.strAssunto,
                strDestinatario: arrayDestinatario.join(" - "),
                strStatus: strStatus,
                strDataAtualizacao: this.$utilidade.toDate(
                  objModel.strDataAtualizacao
                ),
                buttons: [],
                _item: objModel,
              };

              if (objModel.strMensagem) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("arquivo"),
                  color: "primary",
                  title: "Clique para visualizar a mensagem enviada",
                  click: this.visualizarMensagem,
                });
              }

              if (objModel.strLog && objModel.strLog.length) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("risco"),
                  color: "warning",
                  title: "Clique para visualizar os logs de envio",
                  click: this.visualizarLog,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("System/Email", this.formData)
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.bind.boolExibirCadastro = false;
            this.getEmail();
          }
        });
    },
  },
};
</script>
