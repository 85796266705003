<template>
  <div>
    <ed-modal v-model="formFiltros.boolExibirModalEdit" width="70%" :scrollable="false">
      <div slot="title">
        Inserir Novo Serviço ::
        {{
          formData.destino
            ? formData.destino.strNome
            : ""
        }}
      </div>

      <div class="" slot="content">
        <v-tabs v-model="strTab" centered icons-and-text>
          <v-tab href="#tab-servico-geral">
            Serviço Faturado
            <i :class="$utilidade.getIcone('servico') + ' fa-2x'"></i>
          </v-tab>
          <v-tab href="#tab-servico-avulso">
            Serviço Avulso
            <i :class="$utilidade.getIcone('servico') + ' fa-2x'"></i>
          </v-tab>
        </v-tabs>

        <ed-simple-table
          v-if="strTab == 'tab-servico-geral'"
          :config="{ filterName: 'Buscar' }"
          :filter="formFiltros"
          @filter="buscarServicoFaturado"
          boolFiltroAberto
          disabledRegisterRows
          disabledSearchRows
        >
          <template slot="table-filter">
            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              label="Data da Ocorrência"
              name="arrayData"
              v-model="formFiltros.arrayDataCadastro"
              multiple
              clearable
            />
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formFiltros.intCadastroGeralUnidadeId"
              label="Unidade de Trabalho"
              name="intCadastroGeralUnidadeId"
              item-text="strNome"
              route="Cadastro/CadastroGeralUnidade"
              :filters="{
                intCadastroGeralEmpresaId: this.formData.intDestinoId,
              }"
              clearable
            />
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formFiltros.intCadastroGeralSetorId"
              label="Setor"
              name="intCadastroGeralSetorId"
              item-text="strNome"
              route="Cadastro/CadastroGeralSetor"
              :filters="{
                intCadastroGeralEmpresaId: this.formData.intDestinoId,
              }"
              clearable
            />
          </template>
          <template slot="table-filter-button"> </template>
          <thead>
            <tr>
              <th width="50">
                <ed-input-checkbox
                  v-model="formFiltros.boolSelecionarTudo"
                  @input="selecionarTodosServicos"
                  :disabled="arrayServico.length ? false : true"
                />
              </th>
              <th width="50">#</th>
              <th>Serviço</th>
              <th>Unidade</th>
              <th>Setor</th>
              <th width="180">Data</th>
              <th width="180">Valor</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr :key="index" v-for="(servico, index) in arrayServico">
                <td>
                  <ed-input-checkbox
                    v-model="servico.boolSelecionado"
                    @input="calcularServicoSelecionado"
                  />
                </td>
                <td>{{ index + 1 }}</td>
                <td>
                  {{ servico.strParametroServicoNome }}
                  <span
                    title="Consulta"
                    class="ed-table-info ed-full-width"
                    v-if="servico.intConsultaId"
                  >
                    <a
                      target="_blank"
                      :href="
                        $root.$session.urlSistema +
                        '/consulta/editar/' +
                        servico.intConsultaId
                      "
                    >
                      <i :class="$utilidade.getIcone('consulta')"></i>
                      CONSULTA {{ servico.intConsultaId }}
                    </a>
                  </span>
                  <span
                    title="Exame"
                    class="ed-table-info ed-full-width"
                    v-if="servico.exame"
                  >
                    <a
                      target="_blank"
                      :href="
                        $root.$session.urlSistema +
                        '/cadastro/exame/editar/' +
                        servico.exame.intId
                      "
                    >
                      <i :class="$utilidade.getIcone('exame')"></i>
                      {{ servico.exame.strNome }}
                    </a>
                  </span>
                  <span v-if="servico.funcionario" title="Funcionário">
                    <a
                      target="_blank"
                      :href="
                        $root.$session.urlSistema +
                        '/cadastro/funcionario/editar/' +
                        servico.funcionario.intId
                      "
                    >
                      <i :class="$utilidade.getIcone('funcionario')"></i>
                      {{ servico.funcionario.strNome }}
                    </a>
                  </span>
                </td>
                <td>
                  {{ servico.strCadastroGeralUnidadeNome }}
                </td>
                <td>
                  {{ servico.strCadastroGeralSetorNome }}
                </td>
                <td>{{ $utilidade.toDate(servico.strDataServico, true) }}</td>
                <td>{{ $utilidade.floatToMoney(servico.floatValor) }}</td>
              </tr>
              <tr v-if="!arrayServico.length">
                <td colspan="7" align="center">
                  Nenhum serviço encontrado para o período selecionado
                </td>
              </tr>
              <tr v-if="arrayServico.length">
                <td></td>
                <td></td>
                <th></th>
                <td></td>
                <td></td>
                <th>TOTAL SELECIONADO</th>
                <th>
                  R$ {{ $utilidade.floatToMoney(formFiltros.floatTotal) }} ({{
                    formFiltros.intTotalServico
                  }})
                </th>
              </tr>
            </template>
          </tbody>
        </ed-simple-table>

        <template v-if="strTab == 'tab-servico-avulso'">
          <div class="row">
            <div class="col-12 text-left">
              <ed-button
                class="mt-4 mb-2"
                label="Adicionar nova linha de Serviço"
                @click="inserirServicoAvulso"
                :iconLeft="$utilidade.getIcone('cadastrar')"
                size="100%"
                color="primary"
              />
            </div>
          </div>
          <template v-for="(servico, index) in arrayServico">
            <div class="row" :key="index">
              <ed-input-select
                class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-0 pt-0"
                v-model="servico.intParametroServicoId"
                label="Serviço"
                name="intParametroServicoId"
                :items="bind.arrayServico"
                rules="required"
                @selected="
                  (evento) => {
                    servico.servico = evento ? evento : null;
                  }
                "
              >
                <template slot="append">
                  <ed-button
                    @click="exibirCadastroServico()"
                    :iconLeft="$utilidade.getIcone('cadastrar')"
                    class="mt-0 pa-0"
                    onlyIcon
                    color="success"
                    small
                    text
                  />
                </template>
              </ed-input-select>

              <ed-input-money
                class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-0 pt-0"
                v-model="servico.floatValor"
                label="Valor"
                name="floatValor"
                rules="required"
                @input="calcularServicoSelecionado"
              />

              <ed-button
                class="col-2 mt-7"
                @click="removerServicoAvulso(index)"
                :iconLeft="$utilidade.getIcone('deletar')"
                :disabled="index == 0"
                icon
                onlyIcon
                color="error"
              />
            </div>
          </template>
        </template>

        <div
          class="d-flex justify-content-center"
          v-if="arrayServico.length && strTab == 'tab-servico-geral'"
        >
          <ed-alert type="warning" class="mt-3 mb-3 col-4"
            >Para continuar selecione pelo menos 1 serviço</ed-alert
          >
        </div>
      </div>
      <div slot="footer">
        <ed-button
          class="mt-4 mb-2"
          :label="
            'Inserir Serviços Selecionados (R$ ' +
            $utilidade.floatToMoney(formFiltros.floatTotal) +
            ')'
          "
          @click="inserirServico"
          :iconLeft="$utilidade.getIcone('salvar')"
          :disabled="formFiltros.intTotalServico == 0"
          size="100%"
          color="primary"
        />
      </div>
    </ed-modal>
  </div>
</template>

<script>
import {
  EdForm,
  EdSimpleTable,
  EdInputCheckbox,
  EdInputSelect,
  EdInputAutoComplete,
  EdInputMoney,
  EdModal,
  EdAlert,
  EdInputText,
  EdInputDate,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    formFiltros: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdForm,
    EdSimpleTable,
    EdInputCheckbox,
    EdInputSelect,
    EdInputAutoComplete,
    EdInputMoney,
    EdModal,
    EdAlert,
    EdInputDate,
    EdInputText,
    EdButton,
  },
  inject: [
    "bind",
    "calcularServico",
    "exibirCadastroServico",
    "intTipoMovimentoId",
    "getTotalMovimento",
    "getPercentualDesconto",
    "getPercentualAcrescimo",
  ],
  mounted() {
    this.buscarServicoFaturado();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "tab-servico-geral",
      arrayServico: [],
    };
  },
  watch: {
    strTab() {
      this.arrayServico = [];

      if (this.strTab == "tab-servico-avulso") {
        this.inserirServicoAvulso();
      }
    },
  },
  computed: {},
  methods: {
    initialize() {},

    selecionarTodosServicos(ev) {
      this.arrayServico.forEach((element) => {
        element.boolSelecionado = ev ? true : false;
      });
      this.calcularServicoSelecionado();
    },

    calcularServicoSelecionado() {
      let floatTotal = 0;
      let intTotalServico = 0;
      this.arrayServico.forEach((element) => {
        if (element.boolSelecionado) {
          floatTotal += Number(
            this.$utilidade.moneyToFloat(element.floatValor)
          );
          intTotalServico++;
        }
      });

      this.formFiltros.floatTotal = floatTotal;
      this.formFiltros.intTotalServico = intTotalServico;
      this.calcularServico();
    },

    inserirServico() {
      let floatTotalMovimento = 0;
      this.arrayServico.forEach((element) => {
        if (
          element.boolSelecionado &&
          element.intParametroServicoId 
        ) {
          this.formData.servicos.push(element);
          floatTotalMovimento += Number(
            this.$utilidade.moneyToFloat(element.floatValor)
          );
        }
      });

      if (!floatTotalMovimento) {
        this.$root.$dialog.warning(
          "Favor preencher todos os campos obrigatórios."
        );
        return;
      }
      this.formData.floatTotalMovimento = floatTotalMovimento;
      this.formFiltros.boolExibirModalEdit =
        !this.formFiltros.boolExibirModalEdit;
      this.arrayServico = [];
      this.calcularServico();
    },

    inserirServicoAvulso() {
      this.arrayServico.push({
        boolServicoAvulso: 1,
        boolSelecionado: 1,
        strDataServico: this.$utilidade.toDate(null, true, "YYYY-MM-DD"),
        intParametroServicoId: null,
        strParametroServicoNome: null,
        floatValor: null,
      });
      this.calcularServicoSelecionado();
    },

    removerServicoAvulso(index) {
      if (index == 0) return;

      this.arrayServico.splice(index, 1);
      this.calcularServicoSelecionado();
    },

    buscarServicoFaturado() {
      this.$root.$dialog.loading(true);

      this.formFiltros.floatTotal = 0;
      this.formFiltros.intTotalServico = 0;
      this.formFiltros.boolSelecionarTudo = false;
      this.formFiltros.intTipoMovimentoId = this.intTipoMovimentoId;
      this.formFiltros.strDestinoType = this.formData.strDestinoType;

      this.formFiltros.intCadastroGeralEmpresaId = this.formData.intDestinoId;
      this.$root.$request
        .get("Movimento/CabecalhoMovimento", this.formFiltros)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            objResponse.result.forEach((element) => {
              if (element.exame) {
                element.exame = JSON.parse(element.exame);
              }

              if (element.funcionario) {
                element.funcionario = JSON.parse(element.funcionario);
              }

              if (element.servico) {
                element.servico = JSON.parse(element.servico);
              }

              if (element.point) {
                element.point = JSON.parse(element.point);
              }
            });

            this.arrayServico = objResponse.result;
          }
        });
    },
  },
};
</script>
