<template>
  <div v-if="formData && bind.boolCarregado">
    <ed-modal v-model="bind.boolExibirCadastroSubItem" width="90%">
      <div slot="title">
        {{ formDataItem.strNome }}
      </div>

      <div class="" slot="content">
        <parametro
          v-if="formDataItem && bind.boolExibirCadastroSubItem"
          :strChave="formDataItem.strChave"
          @salvar="(x) => formDataItem.parametros.push(x)"
          :key="'sub-item-' + $root.$session.versao"
          :boolVincularExame="strChave == 'consultaGrupo'"
          :boolVincularRisco="strChave == 'consultaGrupo'"
          boolItem
          :disableIcon="disableIcon"
          :disableColor="disableColor"
        />
      </div>
    </ed-modal>

    <ed-form :formData="formData" :handlerSave="salvar" disableFixedSave>
      <ed-simple-table
        @register="onRegister"
        disabledSearchRows
        :config="{ registerName: 'Inserir novo item' }"
      >
        <thead>
          <tr>
            <th width="150" v-if="!disableCodigo">Código</th>
            <th style="min-width: 30%">Nome</th>
            <th v-if="!boolItem && !disableColor">Cor</th>
            <th v-if="!boolItem && !disableIcon">Icone</th>
            <th v-if="boolVincularExame">Vincular exames</th>
            <th v-if="boolVincularRisco">Vincular riscos</th>
            <th width="50">Status</th>
            <th width="100"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(objParametro, index) in formData.parametros">
            <tr :key="'principal-' + index">
              <td v-if="!disableCodigo">
                <ed-input-text
                  class="ma-2"
                  v-model="objParametro.strCodigo"
                  :name="'codigo-' + objParametro.intiId"
                  :readonly="!objParametro.boolPermitirEdicao"
                  hideDetails
                />
              </td>
              <td>
                <ed-input-text
                  class="ma-2"
                  v-model="objParametro.strNome"
                  :name="'parametro-' + objParametro.intiId"
                  :readonly="!objParametro.boolPermitirEdicao"
                  rules="required"
                  hideDetails
                />
              </td>
              <td v-if="!boolItem && !disableColor">
                <ed-input-color
                  v-if="objParametro.strValor"
                  class="ma-2"
                  v-model="objParametro.strValor.strCor"
                  :name="'parametro-' + objParametro.intiId"
                  hideDetails
                  :color="objParametro.strValor.strCor"
                  :readonly="!objParametro.boolPermitirEdicao"
                />
              </td>
              <td v-if="!boolItem && !disableIcon">
                <ed-input-auto-complete
                  v-if="objParametro.strValor && bind.arrayIcones.length"
                  class="ma-2"
                  v-model="objParametro.strValor.strIcone"
                  :name="'parametro-' + objParametro.intiId"
                  :iconLeft="objParametro.strValor.strIcone"
                  :itemList="bind.arrayIcones"
                  :readonly="!objParametro.boolPermitirEdicao"
                  itemValue="strNome"
                  hideDetails
                  :initializeItems="false"
                />
              </td>
              <td v-if="boolVincularExame">
                <ed-input-auto-complete
                  class="ma-2"
                  v-model="objParametro.strValor.arrayExamesPadrao"
                  route="Cadastro/Exame"
                  :name="'parametro-exame-' + objParametro.intiId"
                  :readonly="!objParametro.boolPermitirEdicao"
                  :itemList="bind.arrayExames"
                  hideDetails
                  :initializeItems="false"
                  multiple
                />
              </td>
              <td v-if="boolVincularRisco">
                <ed-input-auto-complete
                  class="ma-2"
                  v-model="objParametro.strValor.arrayRiscosPadrao"
                  route="Cadastro/Risco"
                  :name="'parametro-risco-' + objParametro.intiId"
                  :itemList="bind.arrayRiscos"
                  :readonly="!objParametro.boolPermitirEdicao"
                  hideDetails
                  :initializeItems="false"
                  multiple
                />
              </td>
              <td>
                <ed-input-switch
                  class="ma-2"
                  v-model="objParametro.boolAtivo"
                  :name="'parametro-' + objParametro.intiId"
                  :disabled="!objParametro.boolPermitirEdicao"
                  hideDetails
                />
              </td>
              <td>
                <ed-button
                  v-if="objParametro.parametros && objParametro.parametros.length"
                  :iconLeft="$utilidade.getIcone('subItem')"
                  :disabled="!objParametro.boolPermitirEdicao"
                  color="warning"
                  title="Sub items"
                  icon
                  onlyIcon
                  @click="onSubItem(objParametro)"
                />

                <ed-button
                  v-if="
                    objParametro.boolPermitirExclusao && objParametro.boolPermitirEdicao
                  "
                  :iconLeft="$utilidade.getIcone('deletar')"
                  color="error"
                  title="Deletar item"
                  icon
                  onlyIcon
                  @click="onDelete(objParametro)"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </ed-simple-table>
    </ed-form>
  </div>
</template>

<script>
import {
  EdForm,
  EdInputText,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputColor,
  EdSimpleTable,
  EdInputAutoComplete,
  EdButton,
  EdModal,
} from "@/components/common/form";

export default {
  name: "parametro",
  props: {
    strChave: {
      type: [Number, String],
      required: true,
      default: null,
    },
    disableCodigo: {
      type: [Boolean],
      required: false,
      default: false,
    },
    disableIcon: {
      type: [Boolean],
      required: false,
      default: false,
    },
    disableColor: {
      type: [Boolean],
      required: false,
      default: false,
    },
    boolVincularExame: {
      type: [Boolean],
      required: false,
      default: false,
    },
    boolVincularRisco: {
      type: [Boolean],
      required: false,
      default: false,
    },
    boolItem: {
      type: [Boolean],
      required: false,
      default: false,
    },
    parametro: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdInputSwitch,
    EdInputColor,
    EdSimpleTable,
    EdInputAutoComplete,
    EdButton,
    EdModal,
  },
  mounted() {
    this.getParametro();
  },
  created() {},
  data() {
    return {
      formData: {},
      formDataItem: {},
      bind: {
        boolExibirCadastroSubItem: false,
        boolCarregado: false,
        arrayIcones: [],
        arrayExames: [],
        arrayRiscos: [],
      },
    };
  },
  watch: {
    strChave() {
      this.getParametro();
    },
  },
  computed: {},
  methods: {
    onSubItem(objParametro) {
      this.formDataItem = Object.assign(objParametro, {});
      this.bind.boolExibirCadastroSubItem = true;
    },

    onDelete(item) {
      this.$root.$dialog
        .confirm("Tem certeza que deseja remover esse item?")
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Common/Parametro", { arrayId: [item.intId] })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.$root.$message.success("Dados excluídos com sucesso");
                this.getParametro();
              }
            });
        });
    },

    onRegister() {
      if (!this.formData.parametros) {
        this.formData.parametros = [];
      }

      this.formData.parametros.push({
        intId: 0,
        boolPermitirExclusao: true,
        boolPermitirEdicao: true,
        intParametroId: this.formData.intId,
        strNome: "",
        strValor: {},
        boolAtivo: 1,
        intOrdem: this.formData.parametros ? this.formData.parametros.length + 1 : 1,
      });
    },

    getParametro() {
      if (this.parametro) {
        this.formData = Object.assign(this.parametro, { strValor: {} });
        this.bind.boolCarregado = true;
        return;
      }

      this.$root.$dialog.loading(true);

      let arrayExames = [];
      let arrayRiscos = [];

      this.$root.$request
        .get("Common/Parametro", {
          strChave: this.strChave,
          boolBuscarSomenteAtivo: false,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            if (!objResponse.result.strValor) {
              objResponse.result.strValor = {};
            }
            objResponse.result.parametros.forEach((element) => {
              if (!element.strValor) {
                element.strValor = {
                  arrayExamesPadrao: [],
                  arrayRiscosPadrao: [],
                };
              }

              if (
                element.strValor.arrayExamesPadrao &&
                element.strValor.arrayExamesPadrao.length
              ) {
                arrayExames = arrayExames.concat(element.strValor.arrayExamesPadrao);
              }

              if (
                element.strValor.arrayRiscosPadrao &&
                element.strValor.arrayRiscosPadrao.length
              ) {
                arrayRiscos = arrayRiscos.concat(element.strValor.arrayRiscosPadrao);
              }
            });
            this.formData = Object.assign(objResponse.result, {});
          }

          if (arrayExames.length && !this.bind.arrayExames.length) {
            this.$root.$request
              .get("Cadastro/Exame", { strBusca: arrayExames })
              .then((objResponse) => {
                if (objResponse.status == 200) {
                  this.bind.arrayExames = objResponse.result.data;
                }
              });
          }

          if (arrayRiscos.length && !this.bind.arrayRiscos.length) {
            this.$root.$request
              .get("Cadastro/Risco", { strBusca: arrayRiscos })
              .then((objResponse) => {
                if (objResponse.status == 200) {
                  this.bind.arrayRiscos = objResponse.result.data;
                }
              });
          }

          this.bind.boolCarregado = true;
        });

      if (!this.bind.arrayIcones.length) {
        this.$root.$request.get("Common/Icone",{intRegistroPagina:1000}).then((objResponse) => {
          if (objResponse.status == 200) {
            this.bind.arrayIcones = objResponse.result.data;
          }
        });
      }
    },

    salvar() {
      let formData = {};

      formData[this.strChave] = this.formData;
      
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Common/Parametro", formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          this.getParametro();
          console.log(' objResponse.result',objResponse.result)
          this.$emit("salvar", objResponse.result);
        }
      });
    },
  },
};
</script>
