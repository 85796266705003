<template>
  <div>
    <ed-log-email
      :intModel="objMovimento.intId"
      strModel="Movimento"
      :strAssunto="formData.strTitulo"
      :intTipoServicoContaEmailId="330"
      :arrayAnexo="[
        {
          strNomeMetodo:'getAnexoBoleto',
          strLabel:'Anexar Boleto',
          strField:'boolAnexarBoleto',
          boolAtivo:0
        },
        {
          strNomeMetodo:'getAnexoNfse',
          strLabel:'Anexar Nota Fiscal de Serviço',
          strField:'boolAnexarNfse',
          boolAtivo:0
        }
      ]"
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdForm,
  EdModal,
  EdInputCheckbox,
  EdInputSelect,
  EdInputText,
  EdInputEditor,
  EdInputTextArea,
} from "@/components/common/form";
import EdLogEmail from "@/components/common/log/email/index";

export default {
  name: "pageCadastroEmpresa",
  props: {
    objMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    objCabecalhoMovimento: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdTable,
    EdForm,
    EdModal,
    EdInputCheckbox,
    EdInputSelect,
    EdInputText,
    EdInputEditor,
    EdInputTextArea,
    EdLogEmail,
  },
  inject: [],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      formData: {
        intId: 0,
        boolGerarCobranca: true,
        strTitulo:
          "RELATÓRIO DE PRESTAÇÃO DE SERVIÇOS - " +
          this.$root.$session.cliente.strNome,
        strMensagem: null,
        strDestinatario: null,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {

    initialize() {
      if (this.objCabecalhoMovimento && this.objCabecalhoMovimento.destino) {
        let arrayEmail = [];
        this.objCabecalhoMovimento.destino.emails.forEach((element) => {
          arrayEmail.push(element.strEmail);
        });
        this.formData.strDestinatarioEmail = arrayEmail.join(";");
      }
    },



  },
};
</script>
