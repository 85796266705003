<template>
  <ed-form
    :formData="formData"
    :handlerSave="salvar"
    v-if="!this.$root.$session.loadingContent"
  >
    <div class="row ma-0 pa-0">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Obrigatórias</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            required
            rules="required"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTipoProfissionalId"
            name="intTipoProfissionalId"
            label="Tipo do Profissional"
            tipo="268"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="formData.strDocumento"
            name="strDocumento"
            label="Documento"
            :disabled="!formData.boolAtivo"
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="formData.strDocumentoUf"
            name="strDocumentoUf"
            label="UF do Documento"
            itemText="strUf"
            itemValue="strUf"
            :items="arrayEstados"
            rules="required"
          />

          <ed-input-money
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="formData.floatValorConsulta"
            name="floatValorConsulta"
            label="Valor da Consulta"
          />

          <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Profissional ativo?"
            rules="required"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
            v-model="formData.boolAssinarDocumento"
            label="Assinar Documento Digitalmente"
            info="Ao marcar essa opção o sistema vai assinar digitalmente os documentos (ASO, PCMSO, PGR, etc) caso tiver certificado digital vinculado para esse profissional"
            name="boolAssinarDocumento"
          />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Outras Informações não Obrigatórias</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strCpf"
            name="strCpf"
            label="CPF"
            rules="cpf"
            v-mask="'999.999.999-99'"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strRg"
            name="strRg"
            label="RG"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNit"
            name="strNit"
            label="NIT"
            rules="pis"
            v-mask="'999.99999.99-9'"
          />
        </div>

        <span class="form-title">Informações de Contato</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strTelefone"
            name="strTelefone"
            label="Telefone"
            v-mask="'(99) 9999-9999'"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strCelular"
            name="strCelular"
            label="Celular"
            v-mask="'(99) 99999-9999'"
          />
        </div>
      </div>
    </div>
  </ed-form>
</template>

<script>
import {
  EdForm,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputAutoComplete,
  EdInputMoney,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intProfissionalId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    boolCadastroRapido: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdForm,
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputSwitch,
    EdInputAutoComplete,
    EdInputMoney,
  },
  mounted() {
    this.getProfissional();
    this.getEstado();
  },
  created() {},
  data() {
    return {
      formData: {
        intId: 0,
        boolAtivo: 1,
        boolAssinarDocumento:0,
      },
      arrayEsocial: [],
      arrayEstados: [],
      boolLoadingEsocial: false,
    };
  },
  watch: {
    intProfissionalId() {
      this.getProfissional();
    },
  },
  methods: {
    getEstado() {
      this.arrayEstados = [];

      this.$root.$request
        .get("System/Estado", { intRegistroPagina: 50 })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.arrayEstados = objResponse.result.data;
          }
        });
    },

    getProfissional() {
      if (!this.intProfissionalId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Profissional", { intId: this.intProfissionalId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let formData = Object.assign(objResponse.result, {});
            this.formData = formData;
          }
        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/Profissional", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name != "cadastro.profissional.edit") {
              if (!this.boolCadastroRapido) {
                this.$router.push({
                  name: "cadastro.profissional.edit",
                  params: { intId: objResponse.result.intId },
                });
              }
              this.$emit("salvar", objResponse.result);
            } else {
              this.getProfissional();
            }
          }
        });
    },
  },
};
</script>
