<template>
  <div>
    <!-- <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>

    </v-tabs> -->

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent && bind.boolCarregado"
      :block="bind.boolLancamentoBloqueado"
    >
      <cadastro-geral
        :formData="formData"
        :key="'cadastro-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intCabecalhoMovimentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intMovimentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intTipoMovimentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    EdButton,
  },
  provide() {
    return {
      bind: this.bind,
      initialize: this.initialize,
      intTipoMovimentoId: this.intTipoMovimentoId,
      getTotalMovimento: this.getTotalMovimento,
      getTotalDescontoParcela: this.getTotalDescontoParcela,
      getTotalAcrescimoParcela: this.getTotalAcrescimoParcela,
      getPercentualDesconto: this.getPercentualDesconto,
      getPercentualAcrescimo: this.getPercentualAcrescimo,
      validarValoresParcela:this.validarValoresParcela,
      isMovimentoFinalizado: this.isMovimentoFinalizado,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intFilialId: this.$root.$session.filial
          ? this.$root.$session.filial.intId
          : null,
        intTipoCadastroGeralId: 58,
        strDestinoType: "App\\Models\\Tenant\\CadastroGeral",
        intParcelamento: 0,
        floatTotalDesconto: "0,00",
        floatTotalAcrescimento: "0,00",
        intDestinoId: null,
        intNumeroAgencia: null,
        intNumeroConta: null,
        strNomeTitular: null,
        strDocumentoTitular: null,
        boolValorDiferentePorParcela:false,
        empresa: null,
        strDescricao: null,
        servicos: [],
        movimentos: [
          {
            intId: 0,
            intParcelamento: 0,
            boolPagamentoEfetuado: 0,
            boolPagamentoCancelado: 0,
            boolExibir: 1,
            strDataVencimento: this.$utilidade.toDate(null, true),
            strDataCompentencia: this.$utilidade.getInicioMesAnterior(),
            strDescricao: null,
            strDataRecebimento: null,
            floatValor: null,
            floatValorRecebido: null,
            flaotValorJuros: null,
            flaotValorMulta: null,
            intParametroFormaPagamentoId: null,
            intContaBancariaId: null,
            intParametroStatusId: null,
          },
        ],
        floatTotalMovimento: 0,
      },
      bind: {
        boolLancamentoBloqueado: false,
        boolLancamentoPago: false,
        boolCarregado: false,
        arrayCategoria: [],
        arrayClassificacao:[],
        arrayFormaPagamento: [],
        arrayParcelamento: [],
        arrayContaBancaria: [],
        arrayStatus: [],
        arrayServico: [],
        arrayTipoDestino: [
          {
            intId: "App\\Models\\Tenant\\CadastroGeral",
            strNome: "Empresas e Parceiros",
          },
          {
            intId: "App\\Models\\Tenant\\Profissional",
            strNome: "Profissionais",
          },
        ],
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Movimento/CabecalhoMovimento", {
          boolInicializar: true,
          intCabecalhoMovimentoId: this.intCabecalhoMovimentoId,
          intTipoMovimentoId: this.intTipoMovimentoId,
        }),
      ]).then(([objDados]) => {
        if (objDados.status == 200 && objDados.result) {
          console.log('objDados',objDados)
          this.bind.arrayFormaPagamento = objDados.result.arrayParametro["financeiroFormaPagamento"][
              "parametros"
            ];
          this.bind.arrayStatus =
            objDados.result.arrayParametro["financeiroStatus"]["parametros"];
          this.bind.arrayServico =
            objDados.result.arrayParametro["financeiroServico"]["parametros"];

          this.bind.arrayParcelamento = objDados.result.arrayParcelamento;
          this.bind.arrayContaBancaria =
            objDados.result.arrayContaBancaria.data;


          this.bind.arrayClassificacao =  objDados.result.arrayParametro["financeiroClassificacao"][
            "parametros"
          ];

          if (this.intTipoMovimentoId == 1443) {
            this.bind.arrayCategoria =
              objDados.result.arrayParametro["financeiroCategoriaReceita"][
                "parametros"
              ];
          } else {
            this.bind.arrayCategoria =
              objDados.result.arrayParametro["financeiroCategoriaDespesa"][
                "parametros"
              ];
          }

          this.formatarMovimento(objDados.result.objCabecalhoMovimento);
        }

        this.$root.$dialog.loading(false);
      });
    },

    getTotalMovimento() {
      return (
        this.formData.floatTotalMovimento -
        this.$utilidade.moneyToFloat(this.formData.floatTotalDesconto) +
        this.$utilidade.moneyToFloat(this.formData.floatTotalAcrescimento)
      );
    },

    getTotalDescontoParcela() {
      let floatValor = 0;
      this.formData.movimentos.forEach((element) => {
        floatValor += element.floatValorDesconto
          ? this.$utilidade.moneyToFloat(element.floatValorDesconto)
          : 0;
      });

      return floatValor;
    },

    getTotalAcrescimoParcela() {
      if (this.formData.movimentos.length <= 1) return 0;

      let floatValor = 0;
      this.formData.movimentos.forEach((element) => {
        floatValor += element.floatValorAcrescimento
          ? this.$utilidade.moneyToFloat(element.floatValorAcrescimento)
          : 0;
      });

      return floatValor;
    },

    getPercentualDesconto() {
      if (this.formData.movimentos.length <= 1) return 0;

      if (this.formData.floatTotalMovimento) {
        return parseFloat(
          (this.$utilidade.moneyToFloat(this.formData.floatTotalDesconto) /
            this.formData.floatTotalMovimento) *
            100,
          2
        ).toFixed(2);
      }
      return 0;
    },

    validarValoresParcela() {
      if (this.formData.movimentos.length <= 1) return 0;

      let floatValor = 0;
      this.formData.movimentos.forEach((element) => {
        floatValor += this.$utilidade.moneyToFloat(element.floatValor);
      });
     
      let floatDiferenca = floatValor-this.getTotalMovimento()

      return floatValor;
    },

    getPercentualAcrescimo() {
      if (this.formData.floatTotalMovimento) {
        return parseFloat(
          (this.$utilidade.moneyToFloat(this.formData.floatTotalAcrescimento) /
            this.formData.floatTotalMovimento) *
            100,
          2
        ).toFixed(2);
      }
      return 0;
    },

    isMovimentoFinalizado(objMovimento) {
      if (
        objMovimento.intId &&
        objMovimento.status &&
        [
          "financeiroStatusRecebido",
          "financeiroStatusCancelado",
          "financeiroStatusBoletoAguardandoPagamento",
        ].indexOf(objMovimento.status.strChave) != -1
      ) {
        return true;
      }

      return false;
    },

    validarEdicaoLancamento() {
      let intTotalMovimentoFinalizado = 0;

      this.formData.movimentos.forEach((objMovimento) => {
        if (this.isMovimentoFinalizado(objMovimento)) {
          intTotalMovimentoFinalizado++;
        }
      });

      this.bind.boolLancamentoPago = intTotalMovimentoFinalizado > 0;
      this.bind.boolLancamentoBloqueado =
        intTotalMovimentoFinalizado == this.formData.movimentos.length;
    },

    formatarMovimento(objCabecalhoMovimento) {
      if (objCabecalhoMovimento) {
        if (objCabecalhoMovimento.filiais.length) {
          objCabecalhoMovimento.intFilialId =
            objCabecalhoMovimento.filiais[0].intFilialId;
        }

        objCabecalhoMovimento.movimentos.forEach((objMovimento, index) => {
          objMovimento.strTag = 'tab-principal-'+index
          
          objMovimento.boolExibir =
            objMovimento.intId == this.intMovimentoId ||
            (!this.intMovimentoId && index == 0) ||
            ["financeiroStatusPendente", "financeiroStatusVencido"].indexOf(
              objMovimento.status.strChave
            ) != -1;
        });
        this.formData = Object.assign(objCabecalhoMovimento, {});
        this.validarEdicaoLancamento();
      }

      this.bind.arrayContaBancaria.forEach((element) => {
        if (element.boolPadrao && !this.formData.movimentos[0].intId) {
          this.formData.movimentos[0].intContaBancariaId = element.intId;
        }
      });

      this.bind.boolCarregado = true;
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.formData.intTipoMovimentoId = this.intTipoMovimentoId;
      this.$root.$request
        .post("Movimento/CabecalhoMovimento", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            if (this.$route.name.indexOf("edit") == -1) {
              this.$router.push({
                name: this.$route.name.replace("register", "edit"),
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.formatarMovimento(objResponse.result);
            }
          }
        });
    },
  },
};
</script>

<style>
.footer {
  color: #363636;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.125;
}
</style>
