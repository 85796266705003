<template>
  <div class="row ">
    <div class="col-12 pr-2">
      <span v-if="intTipoMovimentoId == 1443"
        ><b>Informações do Recebimento</b></span
      >
      <span v-if="intTipoMovimentoId != 1443"
        ><b>Informações do Pagamento</b></span
      >
    </div>
    <ed-input-date
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0"
      v-model="formData.strDataRecebimento"
      :disabled="bind.boolLancamentoBloqueado || formData.boolMovimentoBloqueado ? true : false"
      :label="
        intTipoMovimentoId === 1443
          ? 'Data do Recebimento'
          : 'Data do Pagamento'
      "
      name="strDataRecebimento"
      rules="required"
      clearable
    />

    <ed-input-money
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0 pl-2"
      v-model="formData.floatValorRecebido"
      :disabled="!!formData.boolMovimentoBloqueado"
      :label="intTipoMovimentoId === 1443 ? 'Valor Recebido' : 'Valor Pago'"
      name="floatValor"
      rules="required"
    />

    <ed-input-money
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3pt-0 pr-0 pl-2"
      v-model="formData.floatValorJuros"
      :disabled="!!formData.boolMovimentoBloqueado"
      label="Juros"
      name="flaotValorJuros"
      rules=""
    />

    <ed-input-money
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 pr-0 pl-2"
      v-model="formData.floatValorMulta"
      :disabled="!!formData.boolMovimentoBloqueado"
      label="Multa"
      name="flaotValorMulta"
      rules=""
    />
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputText,
  EdInputSelect,
  EdInputDate,
  EdInputMoney,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  inject: ["intTipoMovimentoId"],
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputText,
    EdInputSelect,
    EdInputDate,
    EdInputMoney,
    EdInputAutoComplete,
  },
  inject: ["bind", "intTipoMovimentoId"],
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {},

    onRegister() {
      this.bind.boolExibirModalEdit = !this.bind.boolExibirModalEdit;
    },
  },
};
</script>
