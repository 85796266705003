<template>
  <div>
    <!--DETALHES DA NOTA-->
    <ed-modal v-model="bind.boolExibirDetalhesBoleto" width="60%">
      <div slot="title" v-if="formData">
        Detalhes da Nota Número {{ formData.intNumeroNota }}
      </div>

      <div class="" slot="content" v-if="formData">
        <ed-simple-table disabledSearchRows disabledRegisterRows>
          <thead>
            <tr>
              <th>Data Cadastro</th>
              <th>Data Vencimento</th>
              <th>Valor</th>
              <th>Usuário</th>
              <th>Observações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in formData.strLog">
              <tr v-if="item && item.strDataCadastro" :key="index">
                <td>
                  {{ $utilidade.toDate(item.strDataCadastro) }}
                </td>
                <td>
                  {{ $utilidade.toDate(item.strDataVencimento, true) }}
                </td>
                <td>
                  {{ $utilidade.floatToMoney(Number(item.floatValor)) }}
                </td>
                <td>
                  {{ item.usuario ? item.usuario.strLogin : "" }}
                </td>
                <td>
                  {{ item.strObservacao }}
                </td>
              </tr>
            </template>
          </tbody>
        </ed-simple-table>
      </div>
    </ed-modal>

    <ed-table
      :key="$root.$session.versao"
      class="mt-5"
      strModel="Movimento/MovimentoNotaFiscal"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :config="{ registerName: 'Emitir Nota Fiscal' }"
      @register="salvar"
      @search="initialize"
      :disabledRegisterRows="validarEmissaoNota()"
      disabledEditRows
      disabledSearchRows
      disabledDeleteRows
    >
    </ed-table>

    <template v-if="formResumo">
      <ed-alert
        v-if="formResumo.error"
        type="warning"
        title="Atençao"
        class="mt-5 mb-5"
        >{{ formResumo.error }}</ed-alert
      >
      <div class="row" v-else>
        <div class="col-12">
          <h5 class="mt-5" style="color:rgba(0,0,0,.6);">Resumo dos Impostos</h5>
          <ed-simple-table class="mb-5" disabledSearchRows disabledRegisterRows >
            <thead style="background:#f7f0ca" >
              <tr>
                <th width="200">Total Serviço</th>
                <th width="200">Base de Cálculo</th>
                <th width="200">ISS</th>
                <th width="300">Retenções</th>
                <th>Valor Líquido</th>
              </tr>
            </thead>
            <tbody style="background:#f7f0ca">
              <tr>
                <td>
                  R$ {{ $utilidade.floatToMoney(formResumo.floatValorServicos) }}
                </td>
                <td>
                  R$ {{ $utilidade.floatToMoney(formResumo.floatBaseCalculo) }}
                </td>
                <td>
                  R$ {{ $utilidade.floatToMoney(formResumo.floatValorIss) }}
                  <span style="font-size: 8pt; color: #333333"
                    >({{
                      $utilidade.floatToMoney(
                        formResumo.floatPercentualAliquota
                      )
                    }}%)</span
                  >
                </td>
                <td>
                  <span class="ed-full-width">
                    <b>PIS:</b> R$ {{ $utilidade.floatToMoney(formResumo.floatValorPis) }}
                    <span style="font-size: 8pt; color: #333333"
                      >({{
                        $utilidade.floatToMoney(formResumo.floatPercentualPis)
                      }}%)</span
                    >
                  </span>

                  <span class="ed-full-width">
                    <b>COFINS:</b>
                    R$ {{ $utilidade.floatToMoney(formResumo.floatValorCofins) }}
                    <span style="font-size: 8pt; color: #333333"
                      >({{
                        $utilidade.floatToMoney(
                          formResumo.floatPercentualCofins
                        )
                      }}%)</span
                    >
                  </span>

                  <span class="ed-full-width"
                    >
                    <b>CSLL:</b>
                    R$ {{ $utilidade.floatToMoney(formResumo.floatValorCsll) }}
                    <span style="font-size: 8pt; color: #333333"
                      >({{
                        $utilidade.floatToMoney(formResumo.floatPercentualCsll)
                      }}%)</span
                    >
                  </span>
                  <span class="ed-full-width"
                    >
                    <b>OUTRAS RETENÇÕES:</b>
                    R$ {{ $utilidade.floatToMoney(formResumo.floatValorOutrasRetencoes) }}
                  </span>
                </td>
                <td>
                  R$ {{ $utilidade.floatToMoney(formResumo.floatValorLiquido) }}
                </td>
              </tr>
            </tbody>
          </ed-simple-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  EdTable,
  EdButton,
  EdModal,
  EdAlert,
  EdSimpleTable,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    intMovimentoId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },
  components: {
    EdTable,
    EdButton,
    EdAlert,
    EdModal,
    EdSimpleTable,
  },
  inject: [],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      loading: false,
      formFiltros: {
        intMovimentoId: this.intMovimentoId,
      },
      formResumo: null,
      formData: null,
      bind: {
        boolExibirDetalhesBoleto: false,
      },
      arrayHeaders: [
        {
          text: "Número da RPS",
          sortable: true,
          value: "intNumeroRps",
        },
        {
          text: "Número da Nota",
          sortable: true,
          value: "intNumeroNota",
        },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
        {
          text: "Data Emissão",
          sortable: true,
          value: "strDataEmissao",
        },
        {
          text: "Data Cancelamento",
          sortable: true,
          value: "strDataCancelamento",
        },
        {
          text: "Mensagem",
          sortable: true,
          value: "strMensagem",
          collapse: 1,
          collapseLabel: "Visualizar...",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getResmumo() {
      this.$root.$request
        .get("Movimento/MovimentoNotaFiscal", {
          boolResumoImpostos: true,
          intMovimentoId: this.intMovimentoId,
        })
        .then((objResult) => {
          console.log("getResmumo", objResult);

          if (objResult.result) {
            this.formResumo = Object.assign(objResult.result, {});
          }
        });
    },

    initialize() {
      if (this.intMovimentoId) {
        this.getResmumo();
      }

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Movimento/MovimentoNotaFiscal", this.formFiltros)
        .then((objResult) => {
          this.objPagination = objResult.result;
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            let arrayRows = [];
            let hideDelete = true;

            objResult.result.data.forEach((objModel) => {
              let strCor = "#c49d27";

              if (objModel.intStatus == 102) {
                strCor = "green";
                hideDelete = true;
              } else if (
                objModel.intStatus == 99 ||
                objModel.intStatus == 500
              ) {
                strCor = "red";
              } else if (objModel.intStatus == 100) {
                strCor = "#5580d6";
              }

              console.log("objModel", objModel);
              let item = {
                intId: objModel.intId,
                intNumeroNota:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.intNumeroNota +
                  "</span>",
                intNumeroRps:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.intNumeroRps +
                  "</span>",
                strSerie:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.strSerie +
                  "</span>",
                strStatus:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.strStatus +
                  "</span>",
                strDataEmissao:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  (objModel.strDataEmissao
                    ? this.$utilidade.toDate(objModel.strDataEmissao)
                    : "") +
                  "</span>",
                strDataCancelamento:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  (objModel.strDataCancelamento
                    ? this.$utilidade.toDate(objModel.strDataCancelamento)
                    : "") +
                  "</span>",
                strMensagem: objModel.strMensagem,
                intStatus: objModel.intStatus,
                buttons: [],
                _item: objModel,
              };

              if (objModel.intStatus == 102) {
                if (objModel.strXml) {
                  item.buttons.push({
                    icone: this.$utilidade.getIcone("xml"),
                    color: "warning",
                    title: "Download do XML",
                    click: this.downloadXml,
                  });
                }

                if (objModel.strUrl) {
                  item.buttons.push({
                    icone: this.$utilidade.getIcone("pdf"),
                    color: "info",
                    title: "Download da Danfe",
                    click: this.downloadDanfe,
                  });
                }

                if (
                  this.$root.$session.verificarPermissaoMenu("nfse.cancelar")
                ) {
                  item.buttons.push({
                    icone: this.$utilidade.getIcone("deletar"),
                    color: "error",
                    title: "Cancelar nota",
                    click: this.cancelarNota,
                  });
                }
              }

              if (objModel.strProtocolo && [100,126].indexOf(objModel.intStatus) != -1) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("pesquisar"),
                  color: "primary",
                  title: "Consultar Lote",
                  click: this.consultarLote,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    validarEmissaoNota() {
      if (!this.intMovimentoId) {
        return true;
      }
      let boolDesabilitarEmissao = false;

      if (this.arrayRows.length) {
        if (this.arrayRows[0].intStatus == 102) {
          boolDesabilitarEmissao = true;
        }
      }

      return boolDesabilitarEmissao;
    },

    detalhesBoleto(item) {
      this.bind.boolExibirDetalhesBoleto = !this.bind.boolExibirDetalhesBoleto;
      this.formData = Object.assign(item, {});
    },

    downloadDanfe(item) {
      // if (item.strUrl) {
      //   open(item.strUrl, "_blank");
      // }

      this.$root.$request.open("Movimento/MovimentoNotaFiscal/Get", {
        boolGerarDanfe: true,
        intId: item.intId,
      });
    },

    downloadXml(item) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("type", "hidden");
      link.href =
        "data:text/plain;base64," + this.$utilidade.base64Encode(item.strXml);
      link.download = "Nota_Fiscal_" + item.intNumeroNota + ".xml";
      link.click();
      document.body.removeChild(link);
    },

    cancelarNota(item) {
      this.$root.$dialog
        .confirm("Tem certeza que deseja cancelar essa nota")
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Movimento/MovimentoNotaFiscal", {
              arrayId: [item.intId],
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.result && objResponse.result.strMensagem) {
                this.$root.$dialog.error(objResponse.result.strMensagem);
              }
              this.initialize();
            });
        });
    },

    consultarLote(item) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Movimento/MovimentoNotaFiscal", {
          boolConsultarLote: true,
          intId: item.intId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.initialize();
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Movimento/MovimentoNotaFiscal", {
          intId: 0,
          intMovimentoId: this.intMovimentoId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.initialize();

          if (objResponse.status == 200) {
          }
        });
    },
  },
};
</script>
